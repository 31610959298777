<template>
  <div>
    <esmp-select
      :placeholder="placeholder"
      filterable
      :remote-method="waitProjectPrint"
      :loading="loading"
      :show-search-results="showSearchResults"
      v-model="val"
      @on-query-change="(v) => query = v"
      @on-select="selected"
    >
      <esmp-select-option
        v-for="r in dropDownResults"
        :key="r.projectId"
        :value="r.projectId"
        :label="r.longName"
      >
        <span v-html="r.longName" />
      </esmp-select-option>
    </esmp-select>
  </div>
</template>

<script>
import axios from 'axios';

const { CancelToken } = axios;
let cancel;

const SEARCH_TIMEOUT_DELAY = 100;

export default {
  name: 'ProjectSearch',
  props: {
    placeholder: {
      type: String,
      default: 'Инвестиционный проект',
    },
    searchParams: {
      type: Object,
      default: () => ({}),
    },
    searchUrl: {
      type: String,
      default: '/api/business-trip/invest-project-search',
    },
    value: {
      type: Object,
      default: () => null,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      val: '',
      dropDownResults: [],
      loading: false,
      query: '',
      searchTimeoutId: null,
      showSearchResults: true,
    };
  },
  methods: {
    async search() {
      if (cancel) cancel();
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (this.query.trim().length >= 3) {
        this.loading = true;

        // eslint-disable-next-line prefer-object-spread
        const searchParams = Object.assign({}, this.searchParams, {
          query: this.query,
        });

        await axios
          .get(this.searchUrl, {
            params: searchParams,
            cancelToken: new CancelToken((c) => {
              cancel = c;
            }),
          })
          .then((response) => {
            this.loading = false;
            this.dropDownResults = response.data;
          })
          .catch((err) => {
            if (axios.isCancel(err)) return;
            // eslint-disable-next-line consistent-return
            return err;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    waitProjectPrint(query) {
      if (this.value && query === this.value.longName) return;
      if (this.value && query === this.value.projectId) return;
      if (cancel) cancel();
      if (this.value) {
        this.$emit('input', null);
      }

      this.dropDownResults = null;

      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = setTimeout(this.search, SEARCH_TIMEOUT_DELAY);
    },
    selected(project) {
      const projectValue = project ? this.dropDownResults.find((r) => r.projectId === project.value) : null;
      this.$emit('input', projectValue);
      this.val = projectValue.projectId;
    },
  },
  watch: {
    value(val) {
      if (val && this.query !== val.longName) {
        this.dropDownResults = [val];
        this.val = val.projectId;
      }
    },
  },
  created() {
    if (this.value) {
      this.dropDownResults = [this.value];
      this.val = this.value.projectId;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
