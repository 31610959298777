<template>
  <div class="stepper">
    <div class="stepper-head">
      <div
        class="stepper-head__item"
        v-for="(step, key) in steps"
        v-html="step"
        :key="`stepper_head_${key}`"
      />

      <div
        class="stepper-head__progress"
        :style="`width: ${(activeStep / stepsCount) * 100}%;`"
      />
    </div>
    <div class="stepper-body">
      <div v-for="(step, key, index) in steps" :key="`stepper_item_${key}`">
        <div
          class="stepper-body__item"
          v-if="index + 1 <= activeStep"
          v-show="activeStep === index + 1"
          :key="index"
        >
          <slot :name="key" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    steps: {
      type: Object,
      default: undefined,
    },
    activeStep: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {
    stepsCount() {
      if (this.steps) return Object.keys(this.steps).length;
      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
$gray-light: #ddd;
/* stepper */
.stepper {
  width: 100%;
  font-weight: 300;
}
.stepper-head {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  counter-reset: stepper;
  border-bottom: 3px solid $gray-light;
}
.stepper-head__item {
  flex-grow: 1;
  padding-bottom: 23px;
  font-size: 20px;
  color: #101828;
  text-align: center;
  letter-spacing: 0;
}
.stepper-head__item::before {
  content: counter(stepper) '. ';
  counter-increment: stepper;
}
.stepper-head__progress {
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 3px;
  background: $color-primary-1-day;
}
.stepper-body__item {
  padding-top: 40px;
}
</style>
