<template>
  <div class="business-trip">
    <stepper :steps="steps" :active-step="activeStep">
      <div slot="commonData">
        <validation-observer ref="step1" v-slot="{ invalid }">
          <template v-if="activeStep === 1">
            <div class="business-trip__top">
              <div class="business-trip__top-title">
                <div class="business-trip__top-icon">
                  <esmp-icon
                    name="arrow-right"
                  />
                </div>
                <div class="business-trip__top-text">
                  Создание заявки на командировку
                </div>
              </div>

              <div class="business-trip__top-content">
                <validation-provider
                  name="Cотрудник"
                  rules="required|dontGPH"
                  v-slot="{ errors }"
                >
                  <employee :error-message="errors[0]" v-model="activeUser" />
                </validation-provider>
              </div>
            </div>

            <div class="business-trip__common">
              <div class="business-trip__common-row">
                <div class="business-trip__common-cell">
                  <validation-provider
                    name="Мобильный телефон"
                    :rules="{ required: true, regex: /^(\+7)\(?[489][0-9]{2}\)?[0-9]{3}?[0-9]{2}?[0-9]{2}$/ }"
                    v-slot="{ errors }"
                  >
                    <esmp-input
                      label="Мобильный телефон в формате +7ХХХХХХХХХХ"
                      :error-message="errors[0]"
                      v-model="commonData.phone"
                    />
                  </validation-provider>
                </div>
                <div class="business-trip__common-cell">
                  <validation-provider
                    name="Цель командировки"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <esmp-select
                      placeholder="Цель командировки"
                      filterable
                      clearable
                      @on-select="setArticle"
                      v-model="commonData.target"
                    >
                      <esmp-select-option
                        v-for="item in targets"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </esmp-select-option>
                    </esmp-select>
                    <div v-if="errors[0]" class="business-trip__error">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </div>
              <div
                class="business-trip__common-row"
                v-if="commonDataTarget && commonDataTarget.description"
              >
                <div class="business-trip__common-cell">
                  <validation-provider
                    name="Детали командировки"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <esmp-input
                      :label="targetDetailsPlaceholder"
                      :error-message="errors[0]"
                      v-model="commonData.details"
                    />
                  </validation-provider>
                </div>
              </div>

              <div class="business-trip__common-row">
                <div class="business-trip__common-cell">
                  <validation-provider
                    name="ЦФО-потребителя"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <esmp-select
                      placeholder="ЦФО-потребителя"
                      disabled
                      @on-select="lookForBusinessProcesses"
                      v-model="commonData.cfd"
                    >
                      <esmp-select-option
                        v-for="item in processedCfd"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </esmp-select-option>
                    </esmp-select>
                    <div v-if="errors[0]" class="business-trip__error">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
                <div class="business-trip__common-cell">
                  <validation-provider
                    name="Статья"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <esmp-select
                      placeholder="Статья"
                      filterable
                      clearable
                      @on-select="lookForBusinessProcesses"
                      v-model="commonData.articles"
                    >
                      <esmp-select-option
                        v-for="item in articles"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </esmp-select-option>
                    </esmp-select>
                    <div v-if="errors[0]" class="business-trip__error">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </div>

              <div
                class="business-trip__common-row"
                v-if="dependence(commonDataArticles, articles[3])"
              >
                <div class="business-trip__common-cell">
                  <validation-provider
                    name="Проект"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <investment-project
                      v-model="commonData.project"
                    />
                    <div v-if="errors[0]" class="business-trip__error">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </div>

              <div class="business-trip__common-row">
                <div class="business-trip__common-cell business-trip__search">
                  <validation-provider
                    name="Руководитель подразделения"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <user-search
                      placeholder="Руководитель подразделения"
                      :is-valid="true"
                      v-model="commonData.head"
                    />
                    <div v-if="errors[0]" class="business-trip__error">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
                <div class="business-trip__common-cell">
                  <template
                    v-if="dependence(commonDataArticles, articles[3], true)"
                  >
                    <validation-provider
                      name="Бизнес-процесс"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <esmp-select
                        placeholder="Бизнес-процесс"
                        filterable
                        clearable
                        @on-select="getCodeProgramsList"
                        v-model="commonData.businessProcess"
                      >
                        <esmp-select-option
                          v-for="item in processedBusinessProcesses"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </esmp-select-option>
                      </esmp-select>
                      <div v-if="errors[0]" class="business-trip__error">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </template>
                </div>
              </div>

              <div
                v-if="dependence(commonDataArticles, articles[3], true)"
                class="business-trip__common-row"
              >
                <div class="business-trip__common-cell">
                  <esmp-checkbox v-model="commonData.hasCodeProgram">
                    Указать код программы
                  </esmp-checkbox>
                </div>
                <div class="business-trip__common-cell">
                  <template v-if="commonData.hasCodeProgram">
                    <validation-provider
                      name="Код программы"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <esmp-select
                        placeholder="Код программы"
                        filterable
                        clearable
                        @on-select="getCodeProgramsList"
                        v-model="commonData.codeProgram"
                      >
                        <esmp-select-option
                          v-for="item in (codePrograms || [])"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </esmp-select-option>
                      </esmp-select>
                      <div v-if="errors[0]" class="business-trip__error">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </template>
                </div>
              </div>
            </div>

            <div class="business-trip__next">
              <esmp-button
                variant="primary"
                size="large"
                :disabled="invalid"
                @click="setStep(2)"
              >
                Далее
              </esmp-button>
            </div>
          </template>
        </validation-observer>
      </div>

      <div slot="expenses">
        <template v-if="activeStep === 2">
          <div class="business-trip__expenses">
            <validation-observer ref="step2" v-slot="{ invalid }">
              <div class="business-trip__expenses-block">
                <div class="business-trip__title">
                  Общие сведения по командировке:
                </div>

                <div class="business-trip__expenses-row">
                  <div class="business-trip__expenses-col">
                    <div class="business-trip__expenses-fieldset">
                      <div
                        class="business-trip__expenses-field business-trip__expenses-field--big"
                      >
                        <validation-provider
                          name="Город назначения"
                          rules="required|city"
                          v-slot="{ errors }"
                        >
                          <destination-component
                            :key="
                              `city_${expenses.cities[0].id}`
                            "
                            placeholder="Город назначения"
                            :is-valid="true"
                            :index="0"
                            :hide-double="false"
                            block="expenses"
                            @input="setCityChange"
                            :value="expenses.cities[0]"
                          />
                          <div v-if="errors[0]" class="business-trip__error">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </div>
                    </div>
                  </div>

                  <div class="business-trip__expenses-col">
                    <div class="business-trip__expenses-fieldset">
                      <div class="business-trip__expenses-field">
                        <validation-provider
                          name="Место назначения"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <esmp-select
                            v-if="organizations && organizations.length"
                            placeholder="Место назначения"
                            filterable
                            clearable
                            :value="expenses.organization"
                            @input="
                              updateExpenses({
                                field: 'organization',
                                value: $event,
                              })
                            "
                          >
                            <esmp-select-option
                              v-for="item in organizations"
                              :key="item.id"
                              :value="item.id"
                            >
                              {{ item.name }}
                            </esmp-select-option>
                          </esmp-select>
                          <div v-if="errors[0]" class="business-trip__error">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </div>
                      <div
                        class="business-trip__expenses-field"
                        v-if="dependence(expensesOrganization, organizations[1])"
                      >
                        <validation-provider
                          name="Другая организация"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <esmp-input
                            ref="otherOrganization"
                            label="Другая организация"
                            :error-message="errors[0]"
                            :value="expenses.otherOrganization"
                            @input="
                              updateExpenses({
                                field: 'otherOrganization',
                                value: $event,
                              })
                            "
                          />
                        </validation-provider>
                      </div>
                    </div>
                  </div>

                  <div class="business-trip__expenses-col">
                    <div class="business-trip__expenses-fieldset">
                      <div
                        class="business-trip__expenses-fieldset-title"
                      >
                        Срок командировки {{ commonTripDays }}
                        {{
                          wordCases(commonTripDays, [
                            daysWords.ONE,
                            daysWords.FEW,
                            daysWords.MANY,
                          ])
                        }}:
                      </div>
                      <div class="business-trip__expenses-fieldset-inner">
                        <div class="business-trip__expenses-field">
                          <validation-provider
                            name="Туда"
                            :rules="{ required: true, datePeriod: { endDay: expenses.commonDayEnd } }"
                            v-slot="{ errors }"
                          >
                            <esmp-datepicker-adaptive
                              placeholder="Туда"
                              :disabled-dates="disabledCommonDayStart"
                              @change="
                                setExpensesDate(
                                  'commonDayStart',
                                  $event,
                                  'setStartFirstChange',
                                )
                              "
                              :value="expenses.commonDayStart"
                            />
                            <div v-if="errors[0]" class="business-trip__error">
                              {{ errors[0] }}
                            </div>
                          </validation-provider>
                        </div>
                        <div class="business-trip__expenses-field">
                          <validation-provider
                            name="Обратно"
                            :rules="{ required: true, datePeriod: { startDay: expenses.commonDayStart } }"
                            v-slot="{ errors }"
                          >
                            <esmp-datepicker-adaptive
                              placeholder="Обратно"
                              :disabled-dates="disabledCommonDayEnd"
                              @change="
                                setExpensesDate(
                                  'commonDayEnd',
                                  $event,
                                  'setEndFirstChange',
                                )
                              "
                              :value="expenses.commonDayEnd"
                            />
                            <div v-if="errors[0]" class="business-trip__error">
                              {{ errors[0] }}
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="business-trip__expenses-col">
                    <div class="business-trip__daily-allowance">
                      Суточные: <span>{{ dailyAllowances }}</span>
                      {{
                        wordCases(dailyAllowances, [
                          rubWords.ONE,
                          rubWords.FEW,
                          rubWords.MANY,
                        ])
                      }}
                    </div>
                  </div>
                </div>

                <div
                  class="business-trip__expenses-row"
                  v-for="(destination, index) in expenses.cities"
                  :key="destination.id"
                >
                  <template v-if="index !== 0">
                    <div class="business-trip__expenses-col">
                      <div class="business-trip__expenses-fieldset">
                        <div
                          class="business-trip__expenses-field business-trip__expenses-field--big"
                        >
                          <validation-provider
                            :name="`Город назначения ${(index + 1)}`"
                            rules="required|city"
                            v-slot="{ errors }"
                          >
                            <destination-component
                              :key="
                                `city_${expenses.cities[index].id}`
                              "
                              placeholder="Город назначения"
                              :is-valid="true"
                              :index="index"
                              block="expenses"
                              :hide-double="false"
                              @input="setCityChange"
                              :value="expenses.cities[index]"
                            />
                            <div v-if="errors[0]" class="business-trip__error">
                              {{ errors[0] }}
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                    </div>
                    <div class="business-trip__expenses-col">
                      <div class="business-trip__expenses-fieldset">
                        <action-btn
                          :is-remove="true"
                          @click="removeExpensesCity(index)"
                        >
                          Удалить город
                        </action-btn>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="business-trip__expenses-row">
                  <div class="business-trip__expenses-col">
                    <div class="business-trip__expenses-fieldset">
                      <action-btn
                        @click="addExpensesCity()"
                      >
                        Добавить город назначения
                      </action-btn>
                    </div>
                  </div>
                </div>
              </div>

              <div class="business-trip__expenses-block">
                <div class="business-trip__title">
                  Проезд:
                </div>
                <transit-block
                  v-for="(item, index) in transit"
                  :fields="item"
                  :is-vip="activeUser ? activeUser.isVIP : false"
                  :current-index="index"
                  :key="item.id"
                  @remove="removeTransitBlock"
                />

                <div class="business-trip__expenses-row">
                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <action-btn
                      @click="addTransit(true)"
                    >
                      Добавить маршрут
                    </action-btn>
                  </div>
                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <div class="business-trip__footnote">
                      В случае раннего отправления\прибытия вы можете
                      воспользоваться услугой заказа автотранспорта, доступной по
                      <esmp-link
                        to="http://r12a.ks.rt.ru:8050/OA_HTML/RF.jsp?function_id=28716
                        &resp_id=-1&resp_appl_id=-1&security_group_id=0&lang_code=RU&params=Rpn0hP2qGDe-99rSD0MoFg"
                        target="_blank"
                      >
                        ссылке
                      </esmp-link>
                    </div>
                  </div>
                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <esmp-checkbox
                      :value="expenses.isNeedHelp"
                      @input="
                        updateExpenses({
                          field: 'isNeedHelp',
                          value: $event,
                        })
                      "
                    >
                      Нужна помощь в подборе билета
                    </esmp-checkbox>
                  </div>
                </div>
              </div>

              <div class="business-trip__expenses-block">
                <div class="business-trip__title">
                  Проживание:
                </div>
                <accommodation-block
                  v-for="(item, index) in accommodation"
                  :fields="item"
                  :is-edit="isEdit"
                  :key="item.id"
                  :current-index="index"
                  @remove="removeAccommodationBlock"
                />

                <div class="business-trip__expenses-row">
                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <action-btn
                      @click="addAccommodation"
                    >
                      Добавить отель
                    </action-btn>
                  </div>

                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <esmp-checkbox
                      :value="expenses.groupBooking"
                      @input="
                        updateExpenses({ field: 'groupBooking', value: $event })
                      "
                    >
                      Групповое бронирование
                    </esmp-checkbox>
                  </div>
                </div>

                <div class="business-trip__expenses-row">
                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <esmp-checkbox
                      :value="expenses.isSelfBooking"
                      @input="
                        updateExpenses({ field: 'isSelfBooking', value: $event })
                      "
                    >
                      Необходим аванс?
                    </esmp-checkbox>
                  </div>
                </div>
                <div
                  class="business-trip__expenses-row"
                  v-if="expenses.isSelfBooking"
                >
                  <div class="business-trip__expenses-col">
                    <div class="business-trip__expenses-fieldset">
                      <div class="business-trip__expenses-field">
                        <validation-provider
                          name="Сумма аванса"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <esmp-input
                            :error-message="errors[0]"
                            label="Сумма аванса"
                            :value="expenses.avansAmount"
                            @input="
                              updateExpenses({
                                field: 'avansAmount',
                                value: $event,
                              })
                            "
                          />
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="business-trip__expenses-col">
                    <div class="business-trip__expenses-fieldset">
                      <div
                        class="business-trip__expenses-field business-trip__expenses-field--big"
                      >
                        <validation-provider
                          name="Комментарий к авансу"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <esmp-select
                            placeholder="Комментарий к авансу"
                            filterable
                            clearable
                            :value="expenses.avansAmountComment ? expenses.avansAmountComment.id : null"
                            @input="
                              updateExpenses({
                                field: 'avansAmountComment',
                                value: $event,
                                list: avansComments,
                              })
                            "
                          >
                            <esmp-select-option
                              v-for="item in avansComments"
                              :key="item.id"
                              :value="item.id"
                            >
                              {{ item.name }}
                            </esmp-select-option>
                          </esmp-select>
                          <div v-if="errors[0]" class="business-trip__error">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="business-trip__result">
                <div class="business-trip__result-inner">
                  <div
                    class="business-trip__result-name"
                  >
                    Общая стоимость командировки:
                  </div>
                  <div
                    class="business-trip__result-value"
                  >
                    {{ commonCostText }} рублей
                  </div>
                  <template v-if="freeFund">
                    <div
                      class="business-trip__result-name"
                    >
                      Свободные фонды:
                    </div>
                    <div
                      class="business-trip__result-value"
                    >
                      {{ freeFund }} рублей
                    </div>
                  </template>
                </div>
                <div class="business-trip__result-actions">
                  <esmp-button
                    view="outline"
                    size="large"
                    @click="setStep(1)"
                  >
                    Назад
                  </esmp-button>
                  <template v-if="isEdit">
                    <esmp-button
                      view="outline"
                      size="large"
                      @click="cancelEdit"
                    >
                      Отменить изменения
                    </esmp-button>
                    <esmp-button
                      view="primary"
                      size="large"
                      :disabled="!isValid"
                      @disabled-click="validateFields"
                      @click="saveEdit"
                    >
                      Сохранить изменения
                    </esmp-button>
                  </template>
                  <esmp-button
                    v-else=""
                    view="primary"
                    size="large"
                    :disabled="invalid"
                    @click="sendBusinessTrip"
                  >
                    Отправить на согласование
                  </esmp-button>
                </div>
              </div>
            </validation-observer>
          </div>
        </template>
      </div>
    </stepper>

    <esmp-modal
      v-model="showModals.destinationModal"
      title="Населенный пункт"
      :width="800"
      :footer-hide="true"
      cancel-text="Закрыть"
      class-name="destination-modal"
      @on-hidden="setActiveDestination(null)"
    >
      <destination-choice
        @input="setCityChange"
        @update-field="updateField"
        @toggle-modal="toggleModal"
      />
    </esmp-modal>
    <esmp-modal
      v-model="showModals.ModalChangeDate"
      title="Изменения дат"
      :width="800"
      cancel-text="Нет"
      ok-text="Да"
      @on-ok="changeDateHandler"
    >
      <p>Скорректировать даты проезда и проживания?</p>
    </esmp-modal>
    <esmp-modal
      v-model="showModals.infoModal"
      :title="showModals.title"
      :width="800"
      cancel-text="Закрыть"
      class-name="info-modal"
    >
      <p v-html="showModals.msg" />
    </esmp-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex';
// import { required, requiredIf, minLength } from 'vuelidate/lib/validators';
import dayjs from 'dayjs';
import { isEmpty, isEqual, isBoolean } from 'lodash';
import isObjectEquality from '@/utils/isObjectEquality';
import {
  businessTrip,
  CHECKLIST_DATA,
  ARTICLES,
  RTK_NAMES,
  EMAIL_REGEX,
} from '@/constants/businessTrip';
import Hub from '@/plugins/event-hub';
import Stepper from './stepper.vue';
import Employee from './employee.vue';
import InvestmentProject from './investment-project.vue';
import UserSearch from './user-search.vue';
import DestinationComponent from './destination/destination-component.vue';
import DestinationChoice from './destination/destination-choice.vue';
import ActionBtn from './action-btn';
import TransitBlock from './transit-block.vue';
import AccommodationBlock from './accommodation-block.vue';

const dontGPH = (user) => !user.isGPH;

export default {
  name: 'BusinessTrip',
  props: {
    isEdit: Boolean,
    data: {
      type: Array,
      default: undefined,
    },
  },
  components: {
    Stepper,
    Employee,
    TransitBlock,
    AccommodationBlock,
    ActionBtn,
    InvestmentProject,
    DestinationComponent,
    DestinationChoice,
    UserSearch,
  },
  data() {
    return {
      // иницциатор
      initiatorUser: null,
      // выбранный
      activeUser: null,
      userCity: null,

      isSending: false,

      steps: {
        commonData: businessTrip.tabs.COMMON_DATA,
        expenses: businessTrip.tabs.EXPENSES,
      },
      activeStep: 1,
      isLoadStep: false, // for setCityChange

      // данные первого шага
      commonData: {
        phone: '',
        target: null,
        details: '',

        cfd: null,
        articles: 'E105010101',
        project: null,

        head: null,
        businessProcess: null,

        hasCodeProgram: false,
        codeProgram: null,
      },

      // словари
      daysWords: businessTrip.dayDuration,
      rubWords: businessTrip.rub,
      dailyAllowance: businessTrip.DAY_PRICE,

      // шаблон для блок проезда (tickets)

      // блоки проезда (tickets)
      // transit: [],

      // шаблон для блок проживания (hotels)
      accommodationReference: {
        id: null,
        zone: null,

        destination: null,
        hotel: '',

        dayStart: null,
        dayEnd: null,

        requireMatch: false,
      },
      // блоки проживания (hotels)
      accommodation: [],

      changeDateFunc: null,

      showModals: {
        title: '',
        msg: '',
        destinationModal: false,
        ModalChangeDate: false,
        infoModal: false,
      },
    };
  },
  computed: {
    ...mapGetters('app', ['isLoading']),
    ...mapGetters('user', ['selectedOrLoggedInUser']),
    ...mapState('user', ['loggedInUser']),
    ...mapState('businessTrip', [
      'limits',
      'activeDestination',
      'expenses',
      'transit',
      'codePrograms',
    ]),
    ...mapGetters('businessTrip', [
      'targets', // цели
      'cfd', // цфо-потребителя
      'businessProcesses',
      'territorialZones',
      'avansComments',
      'organizations',
      'articles',
      'timeOfDay',
      'freeFund',
    ]),
    // select values
    commonDataTarget() {
      if (this.commonData.target) {
        return this.targets.find((t) => t.id === this.commonData.target);
      }
      return null;
    },
    commonDataCfd() {
      if (this.commonData.cfd) {
        return this.processedCfd.find((t) => t.id === this.commonData.cfd);
      }
      return null;
    },
    commonDataArticles() {
      if (this.commonData.articles) {
        return this.articles.find((t) => t.id === this.commonData.articles);
      }
      return null;
    },
    commonDataBusinessProcess() {
      if (this.commonData.businessProcess) {
        return this.processedBusinessProcesses.find((t) => t.id === this.commonData.businessProcess);
      }
      return null;
    },
    commonDataCodeProgram() {
      if (this.commonData.codeProgram) {
        return this.codePrograms.find((t) => t.id === this.commonData.codeProgram);
      }
      return null;
    },
    expensesOrganization() {
      if (this.expenses.organization) {
        return this.organizations.find((t) => t.id === this.expenses.organization);
      }
      return null;
    },

    // валидация дат
    disabledCommonDayStart() {
      return (date) => {
        if (this.isEdit || !this.expenses.commonDayEnd) {
          return false;
        }
        return dayjs(date).isAfter(dayjs(this.expenses.commonDayEnd));
      };
    },
    disabledCommonDayEnd() {
      return (date) => {
        if (this.isEdit || !this.expenses.commonDayStart) {
          return false;
        }
        return dayjs(date).isBefore(dayjs(this.expenses.commonDayStart));
      };
    },

    // преобразованныые цфо-потребителя
    processedCfd() {
      if (this.cfd && this.cfd.length) {
        return this.cfd.map((item) => ({
          code: item.code,
          id: item.id,
          name: `${item.code} ${item.name}`,
        }));
      }

      return [];
    },
    // преобразованные бизнесс-процессы
    processedBusinessProcesses() {
      if (this.businessProcesses && this.businessProcesses.length) {
        return this.businessProcesses.map((item) => ({
          id: item.id,
          name: `${item.id} ${item.name}`,
        }));
      }

      return [];
    },

    // дни и стоимость
    commonTripDays() {
      if (this.expenses.commonDayStart && this.expenses.commonDayEnd) {
        const commonDayStart = dayjs(this.expenses.commonDayStart);
        const commonDayEnd = dayjs(this.expenses.commonDayEnd);

        return commonDayEnd.diff(commonDayStart, 'd') + 1;
      }
      return businessTrip.dayDuration.DEFAULT;
    },
    dailyAllowances() {
      if (this.commonTripDays && this.commonTripDays > 1) {
        return parseInt(this.dailyAllowance * this.commonTripDays, 10);
      }
      return 0;
    },
    transitAllowances() {
      let value = 0;
      this.transit.forEach((transit) => {
        if (!transit.zone) return;

        const way = transit.transitWay;
        const limit = this.limits.find(
          (limitItem) => limitItem.code === transit.zone.id,
        );
        if (limit) {
          const price = limit[`${businessTrip.TICKET_PRICE[way]}TicketsLimit`];

          const serviceCharge = businessTrip.SERVICE_CHARGE[way];

          value += price + serviceCharge;
        }
      });

      return value;
    },
    accommodationAllowances() {
      let value = 0;
      this.accommodation.forEach((item) => {
        if (!(item.zone && item.dayStart && item.dayEnd)) return;

        const limit = this.limits.find(
          (limitItem) => limitItem.code === item.zone.id,
        );
        if (limit) {
          const price = limit.hotelLimit;
          const dayStart = dayjs(item.dayStart);
          const dayEnd = dayjs(item.dayEnd);
          const daysCount = dayEnd.diff(dayStart, 'd');
          const cost = price * daysCount;

          value += cost + businessTrip.SERVICE_CHARGE.hotel;
        }
      });

      return value;
    },
    commonCost() {
      const value = this.dailyAllowances
        + this.transitAllowances
        + this.accommodationAllowances;
      return Math.round(value * 100) / 100;
    },
    commonCostText() {
      return Math.round(this.commonCost).toLocaleString();
    },

    // падежи
    wordCases() {
      return (count, words) => {
        const str = count.toString();
        const lastLetter = str[str.length - 1];
        switch (lastLetter) {
        case '1':
          if (str[str.length - 2] && str[str.length - 2] === '1') {
            return words[2];
          }
          return words[0];
        case '2':
        case '3':
        case '4':
          if (str[str.length - 2] && str[str.length - 2] === '1') {
            return words[2];
          }
          return words[1];
        default:
          return words[2];
        }
      };
    },

    // зависимости полей
    dependence() {
      return (target, value, isReverse) => {
        let result = false;
        if (target && value) result = isObjectEquality(target, value);

        return isReverse ? !result : result;
      };
    },

    isValid() {
      // return !this.$v.$invalid;
      return true;
    },

    // преобразованные данных, пришедшие на редактирование
    editableData() {
      return this.convertDataArrayToObj(this.data);
    },

    targetDetailsPlaceholder() {
      if (this.commonDataTarget) {
        const value = `${this.commonDataTarget.description}`;
        return value.charAt(0).toUpperCase() + value.slice(1);
      }

      return 'Детали командировки';
    },
  },
  methods: {
    ...mapActions('businessTrip', [
      'getTargets',
      'getCfd',
      'getBusinessProcesses',
      'getLimits',
      'getConstants',
      'getFreeFund',
      'setActiveDestination',
      'addExpensesCity',
      'removeExpensesCity',
      'updateExpensesCity',
      'updateExpenses',
      'clearExpenses',
      'addTransit',
      'removeTransitBlock',
      'updateTransit',
      'clearTransit',
      'getFreeFund',
      'getCodePrograms',
      'getRegion',
      'getArea',
    ]),
    ...mapActions('system', ['setLoading']),
    // ...mapActions('ticket', ['fetchTickets']),
    toggleModal(payload) {
      if (isBoolean(this.showModals[payload.modalName])) {
        this.showModals[payload.modalName] = payload.newValue;
      }
    },
    updateField(ctx) {
      if (ctx.type === 'transit') {
        const [field, index] = ctx.id.split('_');
        // this.transit[index][field] = ctx.value;
        this.updateTransit({ index, field, value: ctx.value });
      }
      if (ctx.type === 'accommodation') {
        const index = ctx.id;
        this.accommodation[index].destination = ctx.value;
      }
    },
    // конвертацция данных, пришедших на редактирование
    convertDataArrayToObj(arr) {
      if (arr && arr.length) {
        const value = {};
        arr.forEach((item) => {
          value[item.id] = item.value || item.rows;
        });

        return value;
      }

      return null;
    },
    setStep(step) {
      this.isLoadStep = true;
      this.activeStep = step;
      if (step === 1) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
      setTimeout(() => {
        this.isLoadStep = false;
        this.$refs[`step${step}`].reset();
      }, 10);
    },
    async validateFields() {
      const step1Valid = await this.$refs.step1.validate();
      const step2Valid = await this.$refs.step2.validate();

      return step1Valid && step2Valid;
    },

    // забор данных о выбранном городе
    destinationCityChange(index) {
      const ind = Number(index);
      this.setCityChange(ind);

      const destination = this.expenses.cities[ind];
      destination.region = null;
      destination.area = null;
      if (destination.city && !destination.city.isRegion) {
        const regionCode = `${destination.city.code.substr(0, 2)}000000000`;
        const areaCode = `${destination.city.code.substr(0, 5)}000000`;

        destination.region = axios
          .get('/api/business-trip/addresses', {
            params: {
              searchType: 0, // Region
              regionCode,
            },
          })
          .then((response) => response.data[0])
          .catch((e) => e);

        if (areaCode !== regionCode) {
          destination.area = axios
            .get('/api/business-trip/addresses', {
              params: {
                searchType: 1, // Area
                regionCode,
                districtCode: areaCode,
              },
            })
            .then((response) => response.data[0])
            .catch((e) => e);
        }
      }
    },
    // построение проживания и проездов
    setCityChange() {
      if (
        this.isLoadStep
        || !(this.expenses && this.expenses.cities && this.expenses.cities.length)
      ) {
        return;
      }

      const cityCount = this.expenses.cities.length;

      /**
       *
       * Проезд
       */
      //  добавление проездов, если необходимо
      const transitCount = this.expenses.cities.length + 1; //  проездов на один больше
      if (this.transit.length < transitCount) {
        let transitDiff = transitCount - this.transit.length;
        while (transitDiff) {
          this.addTransit();
          transitDiff -= 1;
        }
      }

      // формирование и заполнение проездов городами
      this.transit.forEach((transitItem, transitIndex) => {
        // const transit = this.transit[transitIndex];
        const isFirst = transitIndex === 0;
        const isLast = transitIndex === cityCount;
        const startPoint = isFirst
          ? this.userCity
          : JSON.parse(JSON.stringify(this.expenses.cities[transitIndex - 1]));
        const endPoint = isLast
          ? this.userCity
          : JSON.parse(JSON.stringify(this.expenses.cities[transitIndex]));

        // eslint-disable-next-line no-param-reassign
        // transit.startPoint.$model = Object.assign({}, startPoint);
        this.updateTransit({
          index: transitIndex,
          field: 'startPoint',
          // eslint-disable-next-line prefer-object-spread
          value: Object.assign({}, startPoint),
        });
        // eslint-disable-next-line no-param-reassign
        // transit.endPoint.$model = Object.assign({}, endPoint);
        this.updateTransit({
          index: transitIndex,
          field: 'endPoint',
          // eslint-disable-next-line prefer-object-spread
          value: Object.assign({}, endPoint),
        });

        // eslint-disable-next-line no-nested-ternary
        const day = isFirst
          ? this.expenses.commonDayStart
          : isLast
            ? this.expenses.commonDayEnd
            : null;
        // eslint-disable-next-line no-param-reassign
        // transit.day.$model = day ? `${day}` : '';
        this.updateTransit({
          index: transitIndex,
          field: 'day',
          value: day || null,
        });

        // очистка валидации текущего проезда
        this.$refs.step2.reset();
      });

      /**
       *
       * Проживание
       */

      //  добавление проживаний, если необходимо
      const accommodationCount = this.expenses.cities.length;
      if (this.accommodation.length < accommodationCount) {
        let accommodationDiff = accommodationCount - this.accommodation.length;
        while (accommodationDiff) {
          this.addAccommodation();
          accommodationDiff -= 1;
        }
      }

      // формирование и заполнение проживаний городом
      this.accommodation.forEach((accommodationItem, accommodationIndex) => {
        const accommodation = this.accommodation[
          accommodationIndex
        ];
        const isFirst = accommodationIndex === 0;
        const isLast = accommodationIndex === cityCount - 1;
        const expensesDestination = this.expenses.cities[accommodationIndex];
        // const destination = getDestination(expensesDestination);

        // добавление города
        // eslint-disable-next-line no-param-reassign
        accommodation.destination = JSON.parse(
          JSON.stringify(expensesDestination),
        );

        // добавление дат
        const dayStart = isFirst ? this.expenses.commonDayStart : null;
        const dayEnd = isLast ? this.expenses.commonDayEnd : null;

        // eslint-disable-next-line no-param-reassign
        accommodation.dayStart = dayStart || null;
        // eslint-disable-next-line no-param-reassign
        accommodation.dayEnd = dayEnd || null;

        // очистка валидации текущего проживания
        this.$refs.step2.reset();
      });

      // почему 420?
      // потому что в среднем за 400 пройдет данныые
      // в модель->компонент->каледарь
      // и произойдет событие ввода...
      // надеюсь, что не только у меня в Хроме
      // setTimeout(this.$refs.step2.reset, 420);
    },
    setAccommodationDestination({ index, value }) {
      const accommodation = this.accommodation[index];
      if (accommodation) accommodation.destination = value;
    },
    changeDateHandler() {
      this[this.changeDateFunc]();
    },
    // изменение дат проездов и проживаний при измнении дат командирвоок
    setExpensesDate(field, value, func) {
      this.updateExpenses({ field, value });
      if (!this.isEdit) {
        let clearAccommodation = false;
        if (this.expenses.commonDayStart
          && this.expenses.commonDayEnd
          && this.expenses.commonDayStart === this.expenses.commonDayEnd) {
          clearAccommodation = true;
        }
        this[func](clearAccommodation);
      } else if (this.transit.length || this.accommodation.length) {
        this.changeDateFunc = func;
        this.toggleModal({ modalName: 'ModalChangeDate', newValue: true });
      }
    },
    setStartFirstChange(clearAccommodation) {
      const dateValue = this.expenses.commonDayStart;

      if (this.transit.length) {
        // this.transit[0].day = `${dateValue}`;
        this.updateTransit({
          index: 0,
          field: 'day',
          value: dateValue,
        });
      }

      if (clearAccommodation) {
        this.accommodation = [];
      } else if (this.accommodation.length) {
        this.accommodation[0].dayStart = dateValue;
      }
    },
    setEndFirstChange(clearAccommodation) {
      const dateValue = this.expenses.commonDayEnd;

      if (this.transit.length) {
        const lastTransitInd = this.transit.length - 1;
        // this.transit[lastTransitInd].day = `${dateValue}`;
        this.updateTransit({
          index: lastTransitInd,
          field: 'day',
          value: dateValue,
        });
      }

      if (clearAccommodation) {
        this.accommodation = [];
      } else if (this.accommodation.length) {
        const lastAccommodationInd = this.accommodation.length - 1;
        this.accommodation[lastAccommodationInd].dayEnd = dateValue;
      }
    },

    addAccommodation() {
      const accommodation = JSON.parse(
        JSON.stringify(this.accommodationReference),
      );
      const now = new Date();
      accommodation.id = `accommodation_${now.getTime()}`;

      this.accommodation.push(accommodation);
    },
    removeAccommodationBlock(index) {
      this.accommodation.splice(index, 1);
    },

    // формаирование всех данныых для отправки
    async getBusinessTrip() {
      const getOldValue = (group, index, field) => {
        if (!this.isEdit) return null;

        const currentGroup = this.editableData[group];
        if (currentGroup && index < currentGroup.length) {
          const currentElement = currentGroup[index];
          const value = currentElement.find((item) => item.id === field);
          if (value) {
            return value.value;
          }
        }

        return null;
      };
      const getDestination = (block) => {
        if (!block.region) return null;
        if (block.region.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.region;
        }
        if (block.area?.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.area;
        }

        return isEmpty(block.settlement) ? block.city : block.settlement;
      };
      const createFullPlaceInfo = ({
        region,
        area,
        city,
        settlement,
      }) => ({
        region,
        district: area,
        city,
        locality: settlement,
      });

      const valueArr = [];
      const pushValue = (
        id,
        value,
        valueText,
        targetArr = valueArr,
        oldValue,
        placeInfo /* {
          placeInfoName,
          placeInfoValue
        } */,
      ) => {
        const label = CHECKLIST_DATA[id].label || null;
        const classType = CHECKLIST_DATA[id].classType || null;
        const hidden = CHECKLIST_DATA[id].hidden || false;
        const readOnly = CHECKLIST_DATA[id].readOnly || false;

        const item = {
          id,
          value,
          label,
          classType,
          valueName: valueText || value || null,
          config: {
            label,
            classType,
            hidden,
            readOnly,
          },
        };

        if (this.isEdit) {
          const oldValueField = oldValue || this.editableData[id];
          // eslint-disable-next-line eqeqeq
          if (oldValueField != value) {
            item.oldValue = oldValueField || null;
          }
        }

        if (placeInfo && placeInfo.placeInfoName && placeInfo.placeInfoValue) {
          item[placeInfo.placeInfoName] = placeInfo.placeInfoValue;
        }

        targetArr.push(item);
      };

      const tickets = [];
      const pushTickets = (ticket, index) => {
        // look pushEmptyTickets
        const arr = [];

        pushValue(
          'ticketForm',
          businessTrip.TICKET_FORM[ticket.transitWay],
          businessTrip.TICKET_FORM[ticket.transitWay],
          arr,
          getOldValue('tickets', index, 'ticketForm'),
        );

        pushValue(
          'territoryZone',
          ticket.zone.id,
          ticket.zone.name,
          arr,
          getOldValue('tickets', index, 'territoryZone'),
        );

        const tripClassPlane = ticket.transitWay === businessTrip.transitWays.PLANE
          && ticket.isBusinessClass;
        const tripClassTrain = ticket.transitWay === businessTrip.transitWays.TRAIN
          && ticket.isFirstClass;
        const tripClass = tripClassPlane || tripClassTrain
          ? businessTrip.tripClass.BUSINESS
          : businessTrip.tripClass.ECONOMY;
        pushValue(
          'tripClass',
          tripClass,
          tripClass,
          arr,
          getOldValue('tickets', index, 'tripClass'),
        );

        const ticketStartDate = dayjs(ticket.day).format(
          'YYYY-MM-DD',
        );
        pushValue(
          'ticketStartDate',
          ticketStartDate,
          ticketStartDate,
          arr,
          getOldValue('tickets', index, 'ticketStartDate'),
        );

        const tripNumber = ticket[`${ticket.transitWay}TripNumber`] || null;
        pushValue(
          'tripNumber',
          tripNumber,
          tripNumber,
          arr,
          getOldValue('tickets', index, 'tripNumber'),
        );

        pushValue(
          'departureFrom',
          getDestination(ticket.startPoint).name,
          getDestination(ticket.startPoint).name,
          arr,
          getOldValue('tickets', index, 'departureFrom'),
          {
            placeInfoName: 'fullPlaceInfo',
            placeInfoValue: createFullPlaceInfo(ticket.startPoint),
          },
        );
        pushValue(
          'departureTo',
          getDestination(ticket.endPoint).name,
          getDestination(ticket.endPoint).name,
          arr,
          getOldValue('tickets', index, 'departureTo'),
          {
            placeInfoName: 'fullPlaceInfo',
            placeInfoValue: createFullPlaceInfo(ticket.endPoint),
          },
        );

        pushValue(
          'taxService',
          '1',
          '1',
          arr,
          getOldValue('tickets', index, 'taxService'),
        );
        if (ticket.timeOfDay) {
          pushValue(
            'timeOfDay',
            ticket.timeOfDay.id,
            ticket.timeOfDay.name,
            arr,
            getOldValue('tickets', index, 'timeOfDay'),
          );
        }

        tickets.push(arr);
      };
      const pushEmptyTickets = () => {
        // look pushTickets
        const arr = [];

        pushValue(
          'ticketForm',
          null,
          null,
          arr,
          getOldValue('tickets', 0, 'ticketForm'),
        );
        pushValue(
          'territoryZone',
          null,
          null,
          arr,
          getOldValue('tickets', 0, 'territoryZone'),
        );
        pushValue(
          'tripClass',
          businessTrip.tripClass.ECONOMY,
          businessTrip.tripClass.ECONOMY,
          arr,
          getOldValue('tickets', 0, 'tripClass'),
        );
        pushValue(
          'ticketStartDate',
          null,
          null,
          arr,
          getOldValue('tickets', 0, 'ticketStartDate'),
        );
        pushValue(
          'tripNumber',
          null,
          null,
          arr,
          getOldValue('tickets', 0, 'tripNumber'),
        );
        pushValue(
          'departureFrom',
          null,
          null,
          arr,
          getOldValue('tickets', 0, 'departureFrom'),
        );
        pushValue(
          'departureTo',
          null,
          null,
          arr,
          getOldValue('tickets', 0, 'departureTo'),
        );
        pushValue(
          'taxService',
          '1',
          '1',
          arr,
          getOldValue('tickets', 0, 'taxService'),
        );
        pushValue(
          'timeOfDay',
          null,
          null,
          arr,
          getOldValue('tickets', 0, 'timeOfDay'),
        );

        return arr;
      };

      const hotels = [];
      const pushHotels = (hotel, index) => {
        // look pushEmptyHotels
        const arr = [];

        pushValue(
          'territoryZone',
          hotel.zone.id,
          hotel.zone.name,
          arr,
          getOldValue('hotels', index, 'territoryZone'),
        );

        pushValue(
          'city',
          getDestination(hotel.destination).name,
          getDestination(hotel.destination).name,
          arr,
          getOldValue('hotels', index, 'city'),
          {
            placeInfoName: 'fullPlaceInfo',
            placeInfoValue: createFullPlaceInfo(hotel.destination),
          },
        );

        const ticketStartDate = dayjs(hotel.dayStart).format(
          'YYYY-MM-DD',
        );
        pushValue(
          'residenceFrom',
          ticketStartDate,
          ticketStartDate,
          arr,
          getOldValue('hotels', index, 'residenceFrom'),
        );

        const residenceTo = dayjs(hotel.dayEnd).format(
          'YYYY-MM-DD',
        );
        pushValue(
          'residenceTo',
          residenceTo,
          residenceTo,
          arr,
          getOldValue('hotels', index, 'residenceTo'),
        );

        pushValue(
          'name',
          hotel.hotel,
          hotel.hotel,
          arr,
          getOldValue('hotels', index, 'name'),
        );

        pushValue(
          'category',
          'Согласно грейду',
          'Согласно грейду',
          arr,
          getOldValue('hotels', index, 'category'),
        );
        pushValue(
          'earlyArrive',
          '0',
          '0',
          arr,
          getOldValue('hotels', index, 'earlyArrive'),
        );
        pushValue(
          'lateDeparture',
          '0',
          '0',
          arr,
          getOldValue('hotels', index, 'lateDeparture'),
        );
        pushValue(
          'taxService',
          '1',
          '1',
          arr,
          getOldValue('hotels', index, 'taxService'),
        );

        const requireMatch = hotel.requireMatch ? '1' : '0';
        pushValue(
          'requireMatch',
          requireMatch,
          requireMatch,
          arr,
          getOldValue('hotels', index, 'requireMatch'),
        );

        hotels.push(arr);
      };
      const pushEmptyHotels = () => {
        // look pushHotels
        const arr = [];

        pushValue(
          'territoryZone',
          null,
          null,
          arr,
          getOldValue('hotels', 0, 'territoryZone'),
        );
        pushValue('city', null, null, arr, getOldValue('hotels', 0, 'city'));
        pushValue(
          'residenceFrom',
          null,
          null,
          arr,
          getOldValue('hotels', 0, 'residenceFrom'),
        );
        pushValue(
          'residenceTo',
          null,
          null,
          arr,
          getOldValue('hotels', 0, 'residenceTo'),
        );
        pushValue('name', null, null, arr, getOldValue('hotels', 0, 'name'));
        pushValue(
          'category',
          'Согласно грейду',
          'Согласно грейду',
          arr,
          getOldValue('hotels', 0, 'category'),
        );
        pushValue(
          'earlyArrive',
          '1',
          '1',
          arr,
          getOldValue('hotels', 0, 'earlyArrive'),
        );
        pushValue(
          'lateDeparture',
          '1',
          '1',
          arr,
          getOldValue('hotels', 0, 'lateDeparture'),
        );
        pushValue(
          'taxService',
          '1',
          '1',
          arr,
          getOldValue('hotels', 0, 'taxService'),
        );
        pushValue(
          'requireMatch',
          '0',
          '0',
          arr,
          getOldValue('hotels', 0, 'requireMatch'),
        );

        return arr;
      };

      const destinationPlacesItem = async (destination) => {
        let town = null;
        let area = null;
        let region = null;
        let settlement = null;

        if (destination.city && destination.city.isRegion) {
          region = `${destination.city.name} ${destination.city.abbrev}`;
        } else {
          if (!isEmpty(destination.city)) {
            town = `${destination.city.name} ${destination.city.abbrev}`;
          }

          if (!isEmpty(destination.settlement)) {
            settlement = `${destination.settlement.name} ${destination.settlement.abbrev}`;
          }

          const regionObj = await destination.region;
          region = `${regionObj.name} ${regionObj.abbrev}`;

          if (!isEmpty(destination.area)) {
            const areaObj = await destination.area;
            if (areaObj) area = `${areaObj.name} ${areaObj.abbrev}`;
          }
        }

        return {
          town,
          area,
          region,
          settlement,
        };
      };

      pushValue(
        'employeeNumber',
        this.activeUser.tabNumber,
        this.activeUser.fullName,
      );

      pushValue('type', 'Плановая');
      pushValue('userPosition', this.activeUser.jobFullName);
      pushValue('userBlock', this.activeUser.block);
      pushValue('userOrganization', this.activeUser.organization);
      pushValue('userDepartment', this.activeUser.department);
      pushValue(
        'userEmail',
        this.activeUser.email || this.activeUser.assignmentNumber,
      );
      const isVIPGrade = this.activeUser.isVIPGrade ? '1' : '0';
      pushValue('isVIPGrade', this.activeUser.isVIPGrade, isVIPGrade);

      pushValue(
        'initiatorEmployeeNumber',
        this.loggedInUser.tabNumber || this.loggedInUser.employeeNumber,
        this.loggedInUser.fullName,
      );

      if (this.isEdit) {
        pushValue('requisitionNumber', this.editableData.requisitionNumber);
      }

      pushValue('assignmentId', this.activeUser.assignmentId);

      pushValue(
        'budgetUnit',
        this.activeUser.budgetUnitCode,
        this.activeUser.budgetUnitName,
      );

      pushValue(
        'cfoConsumer',
        this.commonDataCfd.code,
        this.commonDataCfd.name,
      );

      pushValue(
        'expenseItem',
        this.commonDataArticles.id,
        this.commonDataArticles.name,
      );

      const project = this.dependence(
        this.commonDataArticles,
        this.articles[3],
      )
        ? this.commonData.project
        : null;
      pushValue(
        'projectCode',
        project ? project.projectCode : null,
        project ? project.longName : null,
      );

      const businessProcess = this.dependence(
        this.commonDataArticles,
        this.articles[3],
        true,
      )
        ? this.commonDataBusinessProcess
        : null;
      pushValue(
        'businessProcessCode',
        businessProcess ? businessProcess.id : null,
        businessProcess ? businessProcess.name : null,
      );

      const codeProgram = this.dependence(this.commonDataArticles, this.articles[3], true)
        && this.commonData.hasCodeProgram
        ? this.commonDataCodeProgram
        : null;
      pushValue(
        'codeProgram',
        codeProgram ? codeProgram.id : null,
        codeProgram ? codeProgram.name : null,
      );

      pushValue('mobilePhone', this.commonData.phone);

      pushValue('aim', this.commonDataTarget.name);

      const aimDescription = this.commonDataTarget && this.commonDataTarget.description
        ? this.commonData.details
        : 'Без детализации';
      pushValue('aimDescription', aimDescription);

      const headId = this.commonData.head.id || this.commonData.head.tabNumber;
      const headName = this.commonData.head.name || this.commonData.head.fullName;
      pushValue('chiefEmployeeNumber', headId, headName);

      const destinationOrganization = this.dependence(
        this.expensesOrganization,
        this.organizations[1],
      )
        ? this.expenses.otherOrganization
        : this.organizations[0].name;
      pushValue('destinationOrganization', destinationOrganization);

      const commonDayStart = dayjs(this.expenses.commonDayStart).format('DD.MM.YYYY');
      const commonDayEnd = dayjs(this.expenses.commonDayEnd).format('DD.MM.YYYY');
      pushValue('startDate', commonDayStart);
      pushValue('endDate', commonDayEnd);

      const avansAmount = this.expenses.isSelfBooking
        ? this.expenses.avansAmount
        : null;
      pushValue('avansAmount', avansAmount);

      const avansAmountComment = this.expenses.isSelfBooking
        ? this.expenses.avansAmountComment
        : null;
      pushValue(
        'avansAmountComment',
        avansAmountComment ? avansAmountComment.name : null,
      );

      const isNeedHelpFindingTicket = this.expenses.isNeedHelp ? 'да' : 'нет';
      pushValue('isNeedHelpFindingTicket', isNeedHelpFindingTicket);

      pushValue('tripSum', this.commonCost.toFixed(2));

      pushValue('freeFundsAmount', null);

      const destination = this.expenses.cities.length
        ? await destinationPlacesItem(this.expenses.cities[0])
        : null;
      pushValue(
        'destinationPlaceTerritory',
        destination ? 'Российская Федерация' : null,
      );
      pushValue(
        'destinationPlaceRegion',
        destination ? destination.region : null,
        destination ? destination.region : null,
        valueArr,
        null,
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: this.expenses.cities[0].region,
        },
      );
      pushValue(
        'destinationPlaceArea',
        destination ? destination.area : null,
        destination ? destination.area : null,
        valueArr,
        null,
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: this.expenses.cities[0].area,
        },
      );
      pushValue(
        'destinationPlaceTown',
        destination ? destination.town : null,
        destination ? destination.town : null,
        valueArr,
        null,
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: this.expenses.cities[0].city,
        },
      );
      pushValue(
        'destinationPlaceSettlement',
        destination ? destination.settlement : null,
        destination ? destination.settlement : null,
        valueArr,
        null,
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: this.expenses.cities[0].settlement,
        },
      );

      const addPlaces = async () => {
        const place = [];

        for (let i = 0; i < this.expenses.cities.length; i += 1) {
          if (i) {
            const item = this.expenses.cities[i];
            const index = i + 1;

            const itemPlaceArr = [];

            // eslint-disable-next-line no-await-in-loop
            const itemDestination = await destinationPlacesItem(item);

            pushValue(
              'destinationPlaceTerritory',
              'Российская Федерация',
              'Российская Федерация',
              itemPlaceArr,
              getOldValue('place', index, 'destinationPlaceTerritory'),
            );
            pushValue(
              'destinationPlaceRegion',
              itemDestination.region,
              itemDestination.region,
              itemPlaceArr,
              getOldValue('place', index, 'destinationPlaceRegion'),
              {
                placeInfoName: 'placeInfo',
                placeInfoValue: item.region,
              },
            );
            pushValue(
              'destinationPlaceArea',
              itemDestination.area,
              itemDestination.area,
              itemPlaceArr,
              getOldValue('place', index, 'destinationPlaceArea'),
              {
                placeInfoName: 'placeInfo',
                placeInfoValue: item.area,
              },
            );
            pushValue(
              'destinationPlaceTown',
              itemDestination.town,
              itemDestination.town,
              itemPlaceArr,
              getOldValue('place', index, 'destinationPlaceTown'),
              {
                placeInfoName: 'placeInfo',
                placeInfoValue: item.city,
              },
            );
            pushValue(
              'destinationPlaceSettlement',
              itemDestination.settlement,
              itemDestination.settlement,
              itemPlaceArr,
              getOldValue('place', index, 'destinationPlaceSettlement'),
              {
                placeInfoName: 'placeInfo',
                placeInfoValue: item.settlement,
              },
            );

            place.push(itemPlaceArr);
          }
        }

        // eslint-disable-next-line consistent-return
        return place;
      };

      const place = await addPlaces();

      valueArr.push({
        id: 'place',
        groupValue: place,
        groupTemplate: null,
        label: CHECKLIST_DATA.place.label,
      });

      const groupBooking = this.expenses.groupBooking ? 'да' : 'нет';
      pushValue('groupBooking', groupBooking);

      let hotelsGroupTemplate = null;
      if (this.accommodation && this.accommodation.length) {
        this.accommodation.forEach(pushHotels);
      } else {
        hotelsGroupTemplate = pushEmptyHotels();
      }
      valueArr.push({
        id: 'hotels',
        groupValue: hotels,
        groupTemplate: hotelsGroupTemplate,
        label: CHECKLIST_DATA.hotels.label,
      });

      let ticketsGroupTemplate = null;
      if (this.transit && this.transit.length) {
        this.transit.forEach(pushTickets);
      } else {
        ticketsGroupTemplate = pushEmptyTickets();
      }
      valueArr.push({
        id: 'tickets',
        groupValue: tickets,
        groupTemplate: ticketsGroupTemplate,
        label: CHECKLIST_DATA.tickets.label,
      });

      return valueArr;
    },
    // отправка данныых
    async sendBusinessTrip() {
      if (this.isSending) return;

      const isValid = await this.validateFields();
      if (!isValid) return;

      this.isSending = true;
      const requestBody = new FormData();
      const createTicketEntity = {};
      const checklistItems = await this.getBusinessTrip();

      const getDestination = (block) => {
        if (!block.region) return null;
        if (block.region.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.region;
        }

        if (block.area?.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.area;
        }

        return isEmpty(block.settlement) ? block.city : block.settlement;
      };

      const destination = this.expenses.cities
        .map((e) => {
          const d = getDestination(e);
          return `${d.abbrev}. ${d.name}`;
        })
        .join(', ');
      const dateStart = dayjs(this.expenses.commonDayStart).format('DD.MM.YYYY');
      const dateEnd = dayjs(this.expenses.commonDayEnd).format('DD.MM.YYYY');

      createTicketEntity.title = `Командировка в ${destination} с ${dateStart} по ${dateEnd}`;
      createTicketEntity.sourceSystem = 'otrs1';
      createTicketEntity.description = 'Создание заявки на командировку';
      createTicketEntity.typeId = '4';
      createTicketEntity.queue = 'Обработка заявок на командировку КЦ';
      createTicketEntity.priority = '1 normal';
      createTicketEntity.checklistItems = checklistItems;
      createTicketEntity.contactLogin = `${this.loggedInUser.login}`;
      createTicketEntity.customersLogins = [
        this.activeUser.email || this.activeUser.tabNumber,
      ];

      requestBody.append(
        'createTicketEntity',
        JSON.stringify(createTicketEntity),
      );

      this.setLoading({ key: 'page', value: true });
      axios({
        method: 'post',
        url: '/api/business-trip',
        data: requestBody,
      })
        .then(() => {
          this.$EsmpNotify.$show('Ваша заявка успешно создана', 'success');
          this.$router.push({
            name: 'Tickets',
            params: { category: 'active' },
          });
          // this.fetchTickets(true);
        })
        .catch((err) => {
          this.showModals.title = 'Ошибка создания заявки';
          this.showModals.msg = err && err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : err;
          this.toggleModal({ modalName: 'infoModal', newValue: true });
        })
        .finally(() => {
          this.isSending = false;
          this.setLoading({ key: 'page', value: false });
        });
    },

    // получение и подстановка данных
    getUser(user) {
      const params = {};
      if (user.login && EMAIL_REGEX.test(user.login)) {
        params.email = user.login;
      } else {
        params.assignmentNumber = user.employeeNumber;
      }
      return axios
        .get('/api/business-trip/user', {
          params,
        })
        .then((response) => response.data)
        .catch((err) => err);
    },
    getUserByNumber(tabNumber, assignmentId) {
      return axios
        .get('/api/business-trip/user-search-by-number', {
          params: {
            tabNumber,
            assignmentId,
          },
        })
        .then((response) => response.data)
        .catch((err) => err);
    },
    async getUserHead(name, tabNumber) {
      const users = await axios
        .get('/api/business-trip/user-search', {
          params: {
            query: name,
          },
        })
        .then((response) => response.data)
        .catch((err) => err);

      if (users && users.length) {
        return users.find((u) => u.tabNumber === tabNumber);
      }

      return null;
    },
    setCFD() {
      if (
        this.processedCfd
        && this.processedCfd.length
        && (this.activeUser || this.isEdit)
      ) {
        const cfoCode = this.isEdit && !this.commonDataCfd
          ? this.editableData.cfoConsumer
          : this.activeUser.cfoCode;

        const userCfd = this.processedCfd.find((item) => item.code === cfoCode);

        if (userCfd) {
          this.commonData.cfd = userCfd.id;
        }
      }
    },
    lookForBusinessProcesses() {
      this.$nextTick(() => {
        const assignmentId = this.activeUser
          ? this.activeUser.assignmentId
          : null;
        const budgetArticle = this.commonDataArticles
          ? this.commonDataArticles.id
          : null;
        const budgetUnitCode = this.activeUser
          ? this.activeUser.budgetUnitCode
          : null;
        const consumerCFO = this.commonDataCfd
          ? this.commonDataCfd.code
          : null;

        if (budgetArticle && budgetUnitCode && consumerCFO) {
          this.getFreeFund({
            budgetArticle,
            budgetUnit: budgetUnitCode,
            consumerCFO,
          });

          if (assignmentId) {
            this.getBusinessProcesses({
              assignmentId,
              budgetArticle,
              budgetUnitCode,
              consumerCFO,
            });
          }
        }
      });
    },
    checkUserGPH(user) {
      if (!dontGPH(user)) {
        this.showModals.title = 'Ошибка доступа';
        this.showModals.msg = 'Выбранному пользователю услуга заказа командировок не доступна';
        this.toggleModal({ modalName: 'infoModal', newValue: true });
      }
    },

    // инит при создании
    init() {
      this.getConstants();
      this.getTargets();
      this.getCfd().then(this.lookForBusinessProcesses);
      this.getLimits();

      this.addTransit(true); //  to target city
      this.addExpensesCity();
      this.addAccommodation();
      this.addTransit(true); //  from target city

      if (this.selectedOrLoggedInUser) {
        const isSameUser = isEqual(this.selectedOrLoggedInUser, this.loggedInUser);
        this.getUser(this.selectedOrLoggedInUser).then((response) => {
          // eslint-disable-next-line prefer-object-spread
          if (isSameUser) this.initiatorUser = Object.assign({}, response);
          // eslint-disable-next-line prefer-object-spread
          if (!this.activeUser) this.activeUser = Object.assign({}, response);
        });

        if (!isSameUser) {
          this.getUser(this.loggedInUser).then((response) => {
            // eslint-disable-next-line prefer-object-spread
            this.initiatorUser = Object.assign({}, response);
          });
        }
      }
    },
    // инит при редактировании
    async getEditableData() {
      this.isLoadStep = true;
      this.setLoading({ key: 'page', value: true });

      await this.getConstants();

      this.addExpensesCity();

      const getDate = (dateStr) => {
        if (dateStr.includes('.')) {
          return dayjs(dateStr, 'DD.MM.YYYY').toDate();
        }

        return dayjs(dateStr).toDate();
      };

      const isInitiatorActiveUser = this.editableData.initiatorEmployeeNumber === this.editableData.employeeNumber;

      // set initiator User
      if (!isInitiatorActiveUser && this.editableData.initiatorEmployeeNumber) {
        this.initiatorUser = {
          tabNumber: `${this.editableData.initiatorEmployeeNumber}`,
        };
      }

      // set active User (assignmentId, budgetUnit, ?initiator User)
      if (this.editableData.employeeNumber) {
        await this.getUserByNumber(
          this.editableData.employeeNumber,
          this.editableData.assignmentId,
        ).then(async (response) => {
          // eslint-disable-next-line prefer-object-spread
          this.activeUser = Object.assign({}, response);

          this.$nextTick(() => {
            // set mobilePhone
            if (this.editableData.mobilePhone) {
              this.commonData.phone = `${this.editableData.mobilePhone}`;
            }
          });

          if (isInitiatorActiveUser) {
            // eslint-disable-next-line prefer-object-spread
            this.initiatorUser = Object.assign({}, response);
          }

          // set chiefEmployeeNumber (head)
          const isSomeHead = this.activeUser.managerId === this.editableData.chiefEmployeeNumber;
          if (!isSomeHead && this.editableData.chiefEmployeeNumber) {
            const headName = this.data.find(
              (field) => field.id === 'chiefEmployeeNumber',
            ).valueText;
            this.commonData.head = await this.getUserHead(
              headName,
              this.editableData.chiefEmployeeNumber,
            );
          }
        });
      }

      // set cfoConsumer / cfd
      if (this.editableData.cfoConsumer) {
        await this.getCfd().then(this.lookForBusinessProcesses);
      }

      // set expenseItem / articles
      if (this.editableData.expenseItem) {
        const article = this.articles.find(
          (item) => item.id === this.editableData.expenseItem,
        );
        if (article) {
          this.commonData.articles = JSON.parse(JSON.stringify(article)).id;
        }
      }

      // set projectCode
      if (this.editableData.projectCode) {
        await axios
          .get('/api/business-trip/invest-project-search-by-code', {
            params: this.editableData.projectCode,
          })
          .then((response) => {
            this.commonData.project = response.data;
          })
          .catch((err) => err);
      }

      // set businessProcessCode
      if (this.editableData.businessProcessCode) {
        if (!this.businessProcesses || !this.businessProcesses.length) {
          this.lookForBusinessProcesses();
        }
      }

      // set codeProgram
      if (this.editableData.codeProgram) {
        this.commonData.hasCodeProgram = true;
        this.$nextTick(() => {
          const codeProgramName = this.data.find(
            (field) => field.id === 'codeProgram',
          ).valueText;
          const codeProgram = {
            id: this.editableData.codeProgram,
            name: codeProgramName,
          };
          this.commonData.codeProgram = codeProgram.id;
        });
      }

      // set avansAmount
      if (
        this.editableData.avansAmount
        && this.editableData.avansAmountComment
      ) {
        this.updateExpenses({
          field: 'isSelfBooking',
          value: true,
        });
        this.updateExpenses({
          field: 'avansAmount',
          value: `${this.editableData.avansAmount}`,
        });
        await this.getConstants();

        const comment = this.avansComments.find(
          (ac) => ac.name === this.editableData.avansAmountComment,
        );
        if (comment) {
          this.updateExpenses({
            field: 'avansAmountComment',
            // eslint-disable-next-line prefer-object-spread
            value: Object.assign({}, comment),
          });
        }
      }

      // set aim (target)
      if (this.editableData.aim) {
        await this.getTargets().then(() => {
          this.$nextTick(() => {
            const target = this.targets.find(
              (item) => item.name === this.editableData.aim,
            );
            if (target) {
              this.commonData.target = target.id;
            }
          });
        });
      }

      // set aimDescription (details)
      if (this.editableData.aimDescription) {
        this.commonData.details = `${this.editableData.aimDescription}`;
      }

      // set destinationOrganization
      if (this.editableData.destinationOrganization) {
        if (
          !RTK_NAMES.some((n) => n === this.editableData.destinationOrganization)
        ) {
          this.updateExpenses({
            field: 'organization',
            // eslint-disable-next-line prefer-object-spread
            value: this.organizations[1].id,
          });
          this.updateExpenses({
            field: 'otherOrganization',
            value: `${this.editableData.destinationOrganization}`,
          });
        }
      }

      // set startDate
      if (this.editableData.startDate) {
        this.updateExpenses({
          field: 'commonDayStart',
          value: getDate(this.editableData.startDate),
        });
      }

      // set endDate
      if (this.editableData.endDate) {
        this.updateExpenses({
          field: 'commonDayEnd',
          value: getDate(this.editableData.endDate),
        });
      }

      // set isNeedHelpFindingTicket
      if (this.editableData.isNeedHelpFindingTicket) {
        // this.expenses.isNeedHelp =
        //   this.editableData.isNeedHelpFindingTicket === 'да';
        this.updateExpenses({
          field: 'isNeedHelp',
          value: this.editableData.isNeedHelpFindingTicket === 'да',
        });
      }

      // set groupBooking
      if (this.editableData.groupBooking) {
        // this.expenses.groupBooking = this.editableData.groupBooking === 'да';
        this.updateExpenses({
          field: 'groupBooking',
          value: this.editableData.groupBooking === 'да',
        });
      }

      await this.getLimits();
      // set destination
      const setCity = async (region, town) => {
        // simple
        let query = '';
        if (town) {
          query = town;
        } else {
          query = region;
        }

        const queryText = query.replace(/(\sг)$/, '');

        const citySearchValue = await axios
          .get('/api/business-trip/city-search', {
            params: { query: queryText },
          })
          .then((response) => response.data.find((item) => item.name.toLowerCase() === queryText.toLowerCase()))
          .catch((err) => err);

        const value = {
          id: null,
          region: null,
          area: null,
          city: null,
          settlement: null,
        };
        if (!isEmpty(citySearchValue)) {
          if (citySearchValue.isRegion) {
            value.region = citySearchValue;
          } else if (citySearchValue.code) {
            value.city = citySearchValue;

            const regionCode = `${citySearchValue.code.substr(0, 2)}000000000`;
            const areaCode = `${citySearchValue.code.substr(0, 5)}000000`;

            value.region = await this.getRegion(regionCode);

            if (areaCode !== regionCode) {
              value.area = await this.getArea({ regionCode, areaCode });
            }
          }
        }

        return value;
      };
      const russianRegions = await axios
        .get('/api/business-trip/addresses', {
          params: { searchType: 0 },
        })
        .then((response) => response.data)
        .catch((err) => err);
      const getDestination = async (
        // old
        regionName,
        areaName,
        cityName,
        settlementName,
      ) => {
        // hard
        let area = null;
        let city = null;
        let settlement = null;

        const region = russianRegions.find((item) => {
          const itemName = `${item.name} ${item.abbrev}`;
          return itemName.toLowerCase() === regionName.toLowerCase();
        });

        if (areaName) {
          area = await axios
            .get('/api/business-trip/addresses', {
              params: {
                searchType: 1,
                regionCode: region.code,
              },
            })
            .then((response) => response.data.find((item) => {
              const itemName = `${item.name} ${item.abbrev}`;
              return itemName.toLowerCase() === areaName.toLowerCase();
            }))
            .catch((err) => err);
        }

        let districtCode = null;
        if (area) districtCode = area.code;
        else districtCode = region.code;

        if (cityName) {
          city = await axios
            .get('/api/business-trip/addresses', {
              params: {
                searchType: 2,
                regionCode: region.code,
                districtCode,
              },
            })
            .then((response) => response.data.find((item) => {
              const itemName = `${item.name} ${item.abbrev}`;
              return itemName.toLowerCase() === cityName.toLowerCase();
            }))
            .catch((err) => err);
        }

        let cityCode = null;
        if (city) cityCode = city.code;
        else if (area) cityCode = area.code;
        else cityCode = region.code;

        if (settlementName) {
          settlement = await axios
            .get('/api/business-trip/addresses', {
              params: {
                searchType: 3,
                regionCode: region.code,
                districtCode,
                cityCode,
              },
            })
            .then((response) => response.data.find((item) => {
              const itemName = `${item.name} ${item.abbrev}`;
              return itemName.toLowerCase() === settlementName.toLowerCase();
            }))
            .catch((err) => err);
        }

        return {
          region,
          area,
          city,
          settlement,
        };
      };
      const getNewDestination = (id, arr = this.data) => {
        const destination = arr.find((i) => i.id === id);
        return destination || null;
      };
      const parseFullPlaceInfo = ({
        region,
        district,
        city,
        locality,
      }) => ({
        region,
        area: district,
        city,
        settlement: locality,
      });
      const getDestinationBlock = (block) => {
        if (!block.region) return null;
        if (block.region.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.region;
        }
        if (block.area?.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.area;
        }

        return isEmpty(block.settlement) ? block.city : block.settlement;
      };

      const checkDestinationValue = ({ region, city, settlement }) => {
        // eslint-disable-next-line no-nested-ternary
        const direction = settlement.value
          ? settlement
          : city.value
            ? city
            : region;

        if (direction && direction.placeInfo) {
          return (direction.value === `${direction.placeInfo.name} ${direction.placeInfo.abbrev}`);
        }

        return false;
      };

      // ___ set destination first
      if (this.editableData.destinationPlaceRegion) {
        let destination = null;
        if (
          checkDestinationValue({
            region: getNewDestination('destinationPlaceRegion'),
            city: getNewDestination('destinationPlaceTown'),
            settlement: getNewDestination('destinationPlaceSettlement'),
          })
        ) {
          destination = {
            region: getNewDestination('destinationPlaceRegion').placeInfo,
            area: getNewDestination('destinationPlaceArea').placeInfo || null,
            city: getNewDestination('destinationPlaceTown').placeInfo || null,
            settlement:
              getNewDestination('destinationPlaceSettlement').placeInfo || null,
          };
        } else {
          destination = await getDestination(
            this.editableData.destinationPlaceRegion,
            this.editableData.destinationPlaceArea,
            this.editableData.destinationPlaceTown,
            this.editableData.destinationPlaceSettlement,
          );
        }

        destination.id = this.expenses.cities[0].id;

        this.updateExpensesCity({
          index: 0,
          city: destination,
        });
      }
      // ___ set destination another
      if (this.editableData.place && this.editableData.place.length) {
        for (
          let index = 0;
          index < this.editableData.place.length;
          index += 1
        ) {
          this.addExpensesCity();
          const place = this.editableData.place[index];

          const destinationPlaceRegion = place.find(
            (item) => item.id === 'destinationPlaceRegion',
          );
          const destinationPlaceArea = place.find(
            (item) => item.id === 'destinationPlaceArea',
          );
          const destinationPlaceTown = place.find(
            (item) => item.id === 'destinationPlaceTown',
          );
          const destinationPlaceSettlement = place.find(
            (item) => item.id === 'destinationPlaceSettlement',
          );

          let destination = null;

          if (
            checkDestinationValue({
              region: destinationPlaceRegion,
              city: destinationPlaceTown,
              settlement: destinationPlaceSettlement,
            })
          ) {
            destination = {
              region: destinationPlaceRegion.placeInfo,
              area: destinationPlaceArea.placeInfo || null,
              city: destinationPlaceTown.placeInfo || null,
              settlement: destinationPlaceSettlement.placeInfo || null,
            };
          } else {
            // eslint-disable-next-line no-await-in-loop
            destination = await getDestination(
              destinationPlaceRegion.value,
              destinationPlaceArea.value,
              destinationPlaceTown.value,
              destinationPlaceSettlement.value,
            );
          }

          destination.id = this.expenses.cities[index + 1].id;

          this.updateExpensesCity({
            index: index + 1,
            city: destination,
          });
        }
      }

      // set hotels (accommodation)
      const setHotels = async () => {
        for (
          let index = 0;
          index < this.editableData.hotels.length;
          index += 1
        ) {
          const hotelArr = this.editableData.hotels[index];

          this.addAccommodation();

          const hotel = this.convertDataArrayToObj(hotelArr);

          const accommodation = this.accommodation[index];

          accommodation.zone = this.territorialZones.find(
            (zone) => zone.id === hotel.territoryZone,
          );

          if (
            !accommodation.destination
            || hotel.city !== accommodation.destination.name
          ) {
            const city = getNewDestination('city', hotelArr);
            const cityFullPlaceInfo = city.fullPlaceInfo
              ? parseFullPlaceInfo(city.fullPlaceInfo)
              : null;
            if (
              cityFullPlaceInfo
              && getDestinationBlock(cityFullPlaceInfo)
              && getDestinationBlock(cityFullPlaceInfo).name === city.value
            ) {
              accommodation.destination = cityFullPlaceInfo;
            } else {
              // eslint-disable-next-line no-await-in-loop
              accommodation.destination = await setCity(null, hotel.city);
            }
          }

          accommodation.dayStart = getDate(hotel.residenceFrom);

          accommodation.dayEnd = getDate(hotel.residenceTo);

          accommodation.hotel = hotel.name;

          accommodation.requireMatch = hotel.requireMatch === '1';
        }
      };
      const setTransit = async () => {
        for (
          let index = 0;
          index < this.editableData.tickets.length;
          index += 1
        ) {
          const ticketArr = this.editableData.tickets[index];
          this.addTransit(true);

          const ticket = this.convertDataArrayToObj(ticketArr);
          const transit = this.transit[index];

          const transitWay = Object.keys(businessTrip.TICKET_FORM).find(
            (itemKey) => businessTrip.TICKET_FORM[itemKey] === ticket.ticketForm,
          );
          this.updateTransit({
            index,
            field: 'transitWay',
            value: transitWay,
          });

          const zone = this.territorialZones.find(
            // eslint-disable-next-line no-shadow
            (zone) => zone.id === ticket.territoryZone,
          );
          this.updateTransit({
            index,
            field: 'zone',
            value: zone,
          });

          if (ticket.tripClass === businessTrip.tripClass.BUSINESS) {
            if (transit.transitWay === businessTrip.transitWays.PLANE) {
              this.updateTransit({
                index,
                field: 'isBusinessClass',
                value: true,
              });
            } else if (transit.transitWay === businessTrip.transitWays.TRAIN) {
              this.updateTransit({
                index,
                field: 'isFirstClass',
                value: true,
              });
            }
          }

          this.updateTransit({
            index,
            field: 'day',
            value: getDate(ticket.ticketStartDate),
          });

          if (ticket.tripNumber) {
            this.updateTransit({
              index,
              field: `${transit.transitWay}TripNumber`,
              value: ticket.tripNumber,
            });
          }

          if (ticket.departureFrom) {
            let startPoint = null;
            const departureFrom = getNewDestination('departureFrom', ticketArr);
            const departureFromFullPlaceInfo = departureFrom.fullPlaceInfo
              ? parseFullPlaceInfo(departureFrom.fullPlaceInfo)
              : null;

            if (
              departureFromFullPlaceInfo
              && getDestinationBlock(departureFromFullPlaceInfo)
              && getDestinationBlock(departureFromFullPlaceInfo).name === departureFrom.value
            ) {
              startPoint = departureFromFullPlaceInfo;
            } else {
              // eslint-disable-next-line no-await-in-loop
              startPoint = await setCity(null, ticket.departureFrom);
            }

            this.updateTransit({
              index,
              field: 'startPoint',
              value: startPoint,
            });
          }

          if (
            !transit.destination
            || ticket.departureTo !== transit.endPoint.name
          ) {
            let endPoint = null;
            const departureTo = getNewDestination('departureTo', ticketArr);
            const departureToFullPlaceInfo = departureTo.fullPlaceInfo
              ? parseFullPlaceInfo(departureTo.fullPlaceInfo)
              : null;
            if (
              departureToFullPlaceInfo
              && getDestinationBlock(departureToFullPlaceInfo)
              && getDestinationBlock(departureToFullPlaceInfo).name === departureTo.value
            ) {
              endPoint = departureToFullPlaceInfo;
            } else {
              // eslint-disable-next-line no-await-in-loop
              endPoint = await setCity(null, ticket.departureTo);
            }

            this.updateTransit({
              index,
              field: 'endPoint',
              value: endPoint,
            });
          }

          if (ticket.timeOfDay) {
            const timeOfDay = this.timeOfDay.find(
              (item) => item.id === ticket.timeOfDay,
            );

            this.updateTransit({
              index,
              field: 'timeOfDay',
              value: timeOfDay,
            });
          }
        }
      };
      if (this.editableData.hotels && this.editableData.hotels.length) {
        await setHotels();
      }
      if (this.editableData.tickets && this.editableData.tickets.length) {
        await setTransit();
      }

      this.setLoading({ key: 'page', value: false });
      this.isLoadStep = false;
    },

    cancelEdit() {
      this.$emit('close-edit', false);
    },
    async saveEdit() {
      const { source, id } = this.$route.params;
      const checklistItems = await this.getBusinessTrip();

      const requestBody = {
        checklistItems,
        sourceSystem: source,
        ticketId: id,
      };

      this.setLoading({ key: 'page', value: true });
      axios({
        method: 'put',
        url: `/api/business-trip/${id}`,
        data: requestBody,
      })
        .then(() => {
          this.$EsmpNotify.$show('Ваша заявка успешно изменена', 'success');
          this.$router.push({
            name: 'Tickets',
            params: { category: 'active' },
          });
          // this.fetchTickets(true);
        })
        .catch((err) => {
          this.showModals.title = 'Ошибка изменения заявки';
          this.showModals.msg = err && err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : err;
          this.toggleModal({ modalName: 'infoModal', newValue: true });
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },

    clearUserCity() {
      this.userCity = null;
      // this.transit[0].startPoint = null;
      this.updateTransit({
        index: 0,
        field: 'startPoint',
        value: null,
      });
      // this.transit[this.transit.length - 1].endPoint = null;
      this.updateTransit({
        index: this.transit.length - 1,
        field: 'endPoint',
        value: null,
      });
    },
    async setUserCity(user) {
      if (user?.location) {
        let city;
        await axios
          .get('/api/business-trip/city-search', {
            params: {
              query: user.location,
            },
          })
          .then((response) => {
            const citiesList = response.data?.filter((c) => (c.name.toLowerCase() === user.location.toLowerCase()));
            if (citiesList?.length === 1) {
              [city] = citiesList;
            }
          })
          .catch((err) => err);

        if (city) {
          const value = {
            id: this.value ? this.value.id : null,
            region: null,
            area: null,
            city: null,
            settlement: null,
          };
          if (!isEmpty(city)) {
            if (city.isRegion) {
              value.region = city;
            } else if (city.code) {
              value.city = city;

              const regionCode = `${city.code.substr(0, 2)}000000000`;
              const areaCode = `${city.code.substr(0, 5)}000000`;

              value.region = await this.getRegion(regionCode);

              if (areaCode !== regionCode) {
                value.area = await this.getArea({ regionCode, areaCode });
              }
            }
          }

          this.userCity = value;
          if (this.transit.length) {
            // this.transit[0].startPoint = Object.assign({}, city);
            this.updateTransit({
              index: 0,
              field: 'startPoint',
              // eslint-disable-next-line prefer-object-spread
              value: Object.assign({}, value),
            });
            // this.transit[this.transit.length - 1].endPoint = Object.assign(
            //   {},
            //   city,
            // );
            this.updateTransit({
              index: this.transit.length - 1,
              field: 'endPoint',
              // eslint-disable-next-line prefer-object-spread
              value: Object.assign({}, value),
            });
          }
        } else {
          this.clearUserCity();
        }
      } else {
        this.clearUserCity();
      }
    },

    setArticle(target) {
      let article = null;
      if (target && target.name === ARTICLES.education.targetName) {
        article = this.articles.find(
          (a) => a.id === ARTICLES.education.articleId,
        );
      } else {
        article = this.articles.find(
          (a) => a.id === ARTICLES.jobAssignment.articleId,
        );
      }
      if (article) {
        this.commonData.articles = article.id;
        this.lookForBusinessProcesses();
      }
    },

    getCodeProgramsList() {
      this.$nextTick(() => {
        const budgetArticle = this.commonDataArticles
          ? this.commonDataArticles.id
          : null;
        const budgetUnitCode = this.activeUser
          ? this.activeUser.budgetUnitCode
          : null;
        const consumerCFO = this.commonDataCfd
          ? this.commonDataCfd.code
          : null;
        const businessProcessId = this.commonDataBusinessProcess
          ? this.commonDataBusinessProcess.id
          : null;

        if (
          budgetArticle
          && budgetUnitCode
          && consumerCFO
          && businessProcessId
        ) {
          this.getCodePrograms({
            budgetArticle,
            budgetUnitCode,
            consumerCFO,
            businessProcessId,
          });
        }
      });
    },
  },
  created() {
    this.clearExpenses();
    this.clearTransit();

    if (this.isEdit) {
      this.getEditableData();
    } else {
      this.init();
    }
  },
  mounted() {
    Hub.$on('setAccommodationDestination', this.setAccommodationDestination);
    Hub.$on('toggle-modal', this.toggleModal);
  },
  destroyed() {
    Hub.$off('setAccommodationDestination', this.setAccommodationDestination);
    Hub.$off('toggle-modal', this.toggleModal);
  },
  watch: {
    selectedOrLoggedInUser(val) {
      if (val) {
        // подстановка инцциализатора и текущего юзера
        this.getUser(val).then((response) => {
          // eslint-disable-next-line prefer-object-spread
          this.initiatorUser = Object.assign({}, response);
          // eslint-disable-next-line prefer-object-spread
          this.activeUser = Object.assign({}, response);
        });
      }
    },
    activeUser(val) {
      // подстановка данных, зависящих от выбранного юзера
      if (val) {
        // this.commonData.phone = val.phone;

        if (val.manager && val.managerTabNumber) {
          this.commonData.head = {
            tabNumber: `${val.managerTabNumber}`,
            assignmentId: '',
            fullName: `${val.manager}`,
          };
        } else {
          this.commonData.head = null;
        }
      }

      this.setUserCity(val);
      this.lookForBusinessProcesses();
      this.setCFD();
      if (val) this.checkUserGPH(val);
    },
    cfd() {
      this.setCFD();
    },
    processedBusinessProcesses(val) {
      if (val && val.length === 1) {
        this.commonData.businessProcess = val[0].id;
      } else if (val && val.length) {
        if (this.commonDataBusinessProcess) {
          const index = val.findIndex(
            (process) => process.id === this.commonDataBusinessProcess.id,
          );
          if (index === -1) this.commonData.businessProcess = null;
        } else if (this.isEdit && !this.commonDataBusinessProcess) {
          //  set value, if isEdit (at start)
          const businessProcess = val.find(
            (process) => process.id === this.editableData.businessProcessCode,
          );
          if (businessProcess) {
            this.commonData.businessProcess = businessProcess.id;
          }
        }
      }

      this.getCodeProgramsList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/blocks/business-trip.scss';
$bg-gray: #f5f5f5;
$gray: #999;
$gray-light: #ddd;
$gray-dark: #666;

::v-deep .esmp-input-element,
::v-deep .esmp-select-head {
  background-color: rgba(16, 24, 40, 0.05);
}

::v-deep .esmp-datepicker-dropdown {
  z-index: 100;
}

/***  business-trip top ***/
.business-trip__top {
  padding: 20px;
  margin: 0 0 43px;
  background: $bg-gray;
  border: 1px solid $gray-light;
  border-radius: 8px;
}

.business-trip__top-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.business-trip__top-icon {
  margin: 0 14px;
}

.business-trip__top-text {
  font-size: 24px;
  color: $black;
}

/*** business-trip__common ***/
.business-trip__common-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;

  & + & {
    margin-top: 24px;
  }
}

.business-trip__common-cell {
  width: calc(50% - 10px);
}

.business-trip__common-cell:first-child:last-child {
  width: 100%;
}

/*** business-trip__next ***/
.business-trip__next {
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 100px;
  text-align: right;

  .base-button {
    display: inline-block;
  }
}

/*** business-trip__result ***/
.business-trip__result {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 200px;
}

.business-trip__result-inner {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 300px;
  min-height: 150px;
  padding: 25px;
  text-align: left;
  border: 1px solid $gray-light;
}

.business-trip__result-name {
  font-size: 16px;
  color: $gray-dark;
  letter-spacing: 0;
  &:not(:last-child) {
    margin-bottom: 9px;
  }
}

.business-trip__result-value {
  font-size: 24px;
  color: #101828;
  letter-spacing: 0;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.business-trip__result-actions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: -20px;
}

.business-trip__result-actions ::v-deep .esmp-button {
  margin-left: 20px;
}

.business-trip__search .bus__input {
  font-size: 16px;
  background-color: $bg-gray;

  &::placeholder {
    font-size: 16px;
    color: $gray;
  }
}

.business-trip__footnote ::v-deep .esmp-link {
  font-size: 16px;
}
.business-trip__error,
::v-deep .business-trip__error {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;
  font-feature-settings: "tnum", "lnum";
  padding: 0 12px;
  color: rgba(255, 12, 12, 0.8);
 }
</style>
