<template>
  <div class="transit-block">
    <div class="business-trip__expenses-row">
      <div class="business-trip__expenses-col">
        <div class="transport-type">
          <div
            class="transport-type__item"
            :class="{
              'transport-type__item--active':
                fields.transitWay === plane,
            }"
            role="button"
            @click="
              update({
                field: 'transitWay',
                value: plane,
              })
            "
          >
            <svg
              width="20"
              height="19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 9.5c0-.8-.7-1.5-1.5-1.5H13L8 0H6l2.5 8H3L1.5
                6H0l1 3.5L0 13h1.5L3 11h5.5L6 19h2l5-8h5.5c.8 0 1.5-.7 1.5-1.5z"
                fill="#DDD"
                fill-rule="evenodd"
              />
            </svg>
          </div>
          <div
            class="transport-type__item"
            :class="{
              'transport-type__item--active':
                fields.transitWay === train,
            }"
            role="button"
            @click="
              update({
                field: 'transitWay',
                value: train,
              })
            "
          >
            <svg
              width="16"
              height="19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 0C3.6 0 0 .5 0 4v9.5C0 15.4 1.6 17 3.5 17L2 18.5v.5h12v-.5L12.5
                17c1.9 0 3.5-1.6 3.5-3.5V4c0-3.5-3.6-4-8-4zM3.5 15c-.8 0-1.5-.7-1.5-1.5S2.7
                12 3.5 12s1.5.7 1.5 1.5S4.3 15 3.5 15zM7 9H2V4h5v5zm5.5 6c-.8
                0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zM14 9H9V4h5v5z"
                fill="#DDD"
                fill-rule="evenodd"
              />
            </svg>
          </div>
          <div
            v-if="false"
            class="transport-type__item"
            :class="{
              'transport-type__item--active': fields.transitWay === bus,
            }"
            role="button"
            @click="
              update({
                field: 'transitWay',
                value: bus,
              })
            "
          >
            <svg
              width="16"
              height="19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 16.2V18c0 .6.4 1 1 1h1c.6 0 1-.4 1-1v-1h8v1c0 .6.4 1 1
                1h1c.6 0 1-.4 1-1v-1.8c.6-.5 1-1.3 1-2.2V4c0-3.5-3.6-4-8-4S0
                .5 0 4v10c0 .9.4 1.7 1 2.2zM3.5 15c-.8 0-1.5-.7-1.5-1.5S2.7
                12 3.5 12s1.5.7 1.5 1.5S4.3 15 3.5 15zm9 0c-.8
                0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zM14 9H2V4h12v5z"
                fill="#DDD"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="business-trip__expenses-col" v-show="false">
        <div class="business-trip__expenses-fieldset ">
          <div
            class="business-trip__expenses-field business-trip__expenses-field--big"
          >
            <esmp-select
              placeholder="Территориальная зона"
              filterable
              clearable
              :value="fields.zone ? fields.zone.id : null"
              @input="
                update({
                  field: 'zone',
                  value: $event,
                  list: territorialZones,
                })
              "
            >
              <esmp-select-option
                v-for="item in territorialZones"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </div>
        </div>
      </div>
      <div class="business-trip__expenses-col">
        <div class="way-block">
          <div class="business-trip__expenses-fieldset">
            <div class="business-trip__expenses-field">
              <validation-provider
                :name="`Откуда ${(currentIndex + 1)}`"
                rules="required|city"
                v-slot="{ errors }"
              >
                <destination-component
                  :key="`transitStart_${currentIndex}`"
                  placeholder="Откуда"
                  block="transitStart"
                  :index="currentIndex"
                  :is-valid="true"
                  :value="fields.startPoint"
                />
                <div v-if="errors[0]" class="business-trip__error">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
            <div class="business-trip__expenses-field">
              <validation-provider
                :name="`Куда ${(currentIndex + 1)}`"
                rules="required|city"
                v-slot="{ errors }"
              >
                <destination-component
                  :key="`endPoint_${currentIndex}`"
                  placeholder="Куда"
                  block="transitEnd"
                  :index="currentIndex"
                  :is-valid="true"
                  :value="fields.endPoint"
                  @input="setZone"
                />
                <div v-if="errors[0]" class="business-trip__error">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>
          <div
            class="way-block__switcher"
            role="button"
            @click="switchFields('startPoint', 'endPoint')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="14"
              viewBox="0 0 18 14"
            >
              <path
                fill="#7700ff"
                d="M4 11h7V9H4V6l-4 4 4 4v-3zm14-7l-4-4v3H7v2h7v3l4-4z"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="business-trip__expenses-col">
        <div class="business-trip__expenses-fieldset">
          <div class="business-trip__expenses-field">
            <validation-provider
              :name="`Когда ${(currentIndex + 1)}`"
              rules="required"
              v-slot="{ errors }"
            >
              <esmp-datepicker-adaptive
                placeholder="Когда"
                @change="
                  update({
                    field: 'day',
                    value: $event,
                  })
                "
                :value="fields.day"
              />
              <div v-if="errors[0]" class="business-trip__error">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </div>
      </div>

      <div
        class="business-trip__expenses-col"
        v-if="fields.transitWay === plane"
      >
        <div class="business-trip__expenses-fieldset">
          <esmp-input
            error-message=""
            label="Номер рейса"
            :value="fields.planeTripNumber"
            @input="
              update({
                field: 'planeTripNumber',
                value: $event,
              })
            "
          />
        </div>
      </div>
      <div
        class="business-trip__expenses-col"
        v-if="fields.transitWay === train"
      >
        <div class="business-trip__expenses-fieldset">
          <esmp-input
            error-message=""
            label="Номер поезда"
            :value="fields.trainTripNumber"
            @input="
              update({
                field: 'trainTripNumber',
                value: $event,
              })
            "
          />
        </div>
      </div>
      <div
        class="business-trip__expenses-col"
        v-if="fields.transitWay === bus"
      >
        <div class="business-trip__expenses-fieldset">
          <esmp-input
            error-message=""
            label="Номер рейса"
            :value="fields.busTripNumber"
            @input="
              update({
                field: 'busTripNumber',
                value: $event,
              })
            "
          />
        </div>
      </div>
      <div class="business-trip__expenses-col">
        <div class="business-trip__expenses-fieldset">
          <esmp-select
            placeholder="Отправление"
            filterable
            clearable
            :disabled="isHasTripNumber"
            :value="fields.timeOfDay ? fields.timeOfDay.id : null"
            @input="
              update({
                field: 'timeOfDay',
                value: $event,
                list: timeOfDay,
              })
            "
          >
            <esmp-select-option
              v-for="item in timeOfDay"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </esmp-select-option>
          </esmp-select>
        </div>
      </div>
      <div
        class="business-trip__expenses-col"
        v-if="isVip && fields.transitWay === plane"
      >
        <esmp-checkbox
          :value="fields.isBusinessClass"
          @input="
            update({
              field: 'isBusinessClass',
              value: $event,
            })
          "
        >
          Бизнес-класс
        </esmp-checkbox>
      </div>

      <div
        class="business-trip__expenses-col"
        v-if="isVip && fields.transitWay === train"
      >
        <esmp-checkbox
          :value="fields.isFirstClass"
          @input="
            update({
              field: 'isFirstClass',
              value: $event,
            })
          "
        >
          Купе 1 класс
        </esmp-checkbox>
      </div>

      <div class="business-trip__expenses-col" v-if="isAvailableRemove">
        <action-btn
          :is-remove="true"
          @click="removeTransit"
        >
          Удалить маршрут
        </action-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import DestinationComponent from '@/components/business-trip/destination/destination-component.vue';
import { businessTrip } from '@/constants/businessTrip';
import ActionBtn from './action-btn';

export default {
  name: 'TransitBlock',
  props: {
    fields: {
      type: Object,
      default: undefined,
    },
    isVip: Boolean,
    currentIndex: {
      type: [Number, String],
      default: undefined,
    },
    isAvailableRemove: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      plane: businessTrip.transitWays.PLANE,
      train: businessTrip.transitWays.TRAIN,
      bus: businessTrip.transitWays.BUS,
      isSwitch: false,
    };
  },
  computed: {
    ...mapGetters('businessTrip', ['territorialZones', 'timeOfDay']),
    isHasTripNumber() {
      const hasNumber = !isEmpty(
        this.fields[`${this.fields.transitWay}TripNumber`],
      );

      if (hasNumber) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.fields.timeOfDay.$model = null;
        this.update({
          field: 'timeOfDay',
          value: null,
        });
        // this.fields.timeOfDay.$reset();
      }

      return hasNumber;
    },
  },
  methods: {
    ...mapActions('businessTrip', ['updateTransit']),
    update({ field, value, list }) {
      let val = value;
      if (list && list.length) {
        val = list.find((i) => i.id === value);
      }
      this.updateTransit({
        index: this.currentIndex,
        field,
        value: val,
      });
    },
    removeTransit() {
      this.$emit('remove', Number(this.currentIndex));
    },
    switchFields(firstField, secondField) {
      if (!this.isSwitch) {
        this.isSwitch = true;

        const thirdGlass = JSON.parse(
          JSON.stringify(this.fields[firstField]),
        );
        this.update({
          field: firstField,
          value: JSON.parse(JSON.stringify(this.fields[secondField])),
        });
        this.update({
          field: secondField,
          value: thirdGlass,
        });

        this.isSwitch = false;
      }
    },
    setZone() {
      if (!this.fields.endPoint) return;
      let zone = null;
      // eslint-disable-next-line no-nested-ternary
      const getDestination = (block) => {
        if (!block.region) return null;
        if (block.region.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.region;
        }
        if (block.area?.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.area;
        }

        return isEmpty(block.settlement) ? block.city : block.settlement;
      };
      const r = getDestination(this.fields.endPoint);
      if (r) {
        zone = this.territorialZones.find(
          (item) => item.id === r.zoneCode || null,
        );
      }

      // this.fields.zone = zone;
      this.update({
        field: 'zone',
        value: zone,
      });
    },
  },
  components: {
    ActionBtn,
    DestinationComponent,
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/blocks/business-trip.scss';

.transport-type {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
.transport-type__item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: rgba(16, 24, 40, 0.05);
  svg path {
    fill: rgba(16, 24, 40, 0.4);
  }
}
.transport-type__item:not(:last-child) {
  margin-right: 3px;
}

.transport-type__item:first-child {
  border-radius: 8px 0 0 8px;
}
.transport-type__item:last-child {
  border-radius: 0 8px 8px 0;
}
.transport-type__item--active svg path {
  fill: $color-primary-1-day;
}

/* switcher */
.way-block {
  position: relative;
}
.way-block__switcher {
  position: absolute;
  top: 50%;
  left: calc(50% - 80px);
  z-index: 6;
  width: 18px;
  height: 14px;
  // background-image: url('~@/assets/icons/switcher.svg');
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(-50%);
}
.way-block__switcher:hover {
  cursor: pointer;
}
</style>
