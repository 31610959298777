<template>
  <div class="destination-choice">
    <h2 class="destination-choice__title">
      Выбор пункта
    </h2>

    <div class="destination-choice__items">
      <div class="destination-choice__item">
        <h3>Регион: </h3>
        <destination-search
          placeholder="Выберите регион"
          :search-params="regionParams"
          hide-double
          @keypress="clear('region')"
          v-model="region"
        />
      </div>

      <div class="destination-choice__item">
        <h3>Район: </h3>
        <destination-search
          placeholder="Выберите район"
          :search-params="areaParams"
          hide-double
          :disabled="!hasRegion"
          @keypress="clear('area')"
          v-model="area"
        />
      </div>

      <div class="destination-choice__item">
        <h3>Город: </h3>
        <destination-search
          placeholder="Выберите город"
          :search-params="cityParams"
          hide-double
          :disabled="!hasRegion"
          @keypress="clear('city')"
          v-model="city"
        />
      </div>

      <div class="destination-choice__item">
        <h3>Населеный пункт (поселок, пгт и т.д.): </h3>
        <destination-search
          placeholder="Выберите населеный пункт"
          :search-params="settlementParams"
          hide-double
          :disabled="!hasRegion"
          v-model="settlement"
        />
      </div>
    </div>

    <div class="destination-choice__actions">
      <esmp-button
        view="outline"
        @click="$emit('toggle-modal', { modalName: 'destinationModal', newValue: false })"
      >
        Закрыть
      </esmp-button>
      <esmp-button :disabled="!canSave" @click="emitInput">
        Сохранить
      </esmp-button>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapState, mapActions, mapGetters } from 'vuex';
import { businessTrip } from '@/constants/businessTrip';
import Hub from '@/plugins/event-hub';
import DestinationSearch from './destination-search.vue';

export default {
  name: 'DestinationChoice',
  components: {
    DestinationSearch,
  },
  data() {
    return {
      region: null,
      area: null, // district
      city: null, // town
      settlement: null, // locality
    };
  },
  computed: {
    ...mapState('businessTrip', ['activeDestination']),
    ...mapGetters('businessTrip', ['editableDestination']),
    canSave() {
      if (
        this.region
        && ((this.region.abbrev === businessTrip.DESTINATION.cityAbbrev)
          || (this.area?.abbrev === businessTrip.DESTINATION.cityAbbrev))
      ) {
        return true;
      }

      return !isEmpty(this.city) || !isEmpty(this.settlement);
    },
    regionParams() {
      return {
        searchType: 0,
      };
    },
    areaParams() {
      return {
        searchType: 1,
        regionCode: this.region ? this.region.code : null,
      };
    },
    cityParams() {
      let districtCode = null;
      if (!isEmpty(this.area)) {
        districtCode = this.area.code;
      } else if (!isEmpty(this.region)) {
        districtCode = this.region.code;
      }

      return {
        searchType: 2,
        regionCode: this.region ? this.region.code : null,
        districtCode,
      };
    },
    settlementParams() {
      let districtCode = null;
      if (!isEmpty(this.area)) {
        districtCode = this.area.code;
      } else if (!isEmpty(this.region)) {
        districtCode = this.region.code;
      }

      let cityCode = null;
      if (!isEmpty(this.city)) {
        cityCode = this.city.code;
      } else if (!isEmpty(this.area)) {
        cityCode = this.area.code;
      } else if (!isEmpty(this.region)) {
        cityCode = this.region.code;
      }

      return {
        searchType: 3,
        regionCode: this.region ? this.region.code : null,
        districtCode,
        cityCode,
      };
    },
    hasRegion() {
      return !isEmpty(this.region);
    },
  },
  methods: {
    ...mapActions('businessTrip', [
      'getArea',
      'updateExpensesCity',
      'updateTransit',
    ]),
    async emitInput() {
      const val = {
        id: this.editableDestination
          ? `${this.editableDestination.id}`
          : this.activeDestination.index,
        region: !isEmpty(this.region) ? this.region : null,
        area: !isEmpty(this.area) ? this.area : null,
        city: !isEmpty(this.city) ? this.city : null,
        settlement: !isEmpty(this.settlement) ? this.settlement : null,
      };

      if (!val.area && (!isEmpty(this.city) || !isEmpty(this.settlement))) {
        const regionCode = `${this.city.code.substr(0, 2)}000000000`;
        const areaCode = `${this.city.code.substr(0, 5)}000000`;

        if (areaCode !== regionCode) {
          val.area = await this.getArea({ regionCode, areaCode });
        }
      }

      if (
        this.activeDestination.block === businessTrip.DESTINATION.accommodation
      ) {
        this.$emit('update-field', {
          type: 'accommodation',
          id: this.activeDestination.index,
          value: val,
        });
        Hub.$emit('toggle-modal', { modalName: 'destinationModal', newValue: false });
        return;
      }
      if (
        this.activeDestination.block === businessTrip.DESTINATION.transitStart
        || this.activeDestination.block === businessTrip.DESTINATION.transitEnd
      ) {
        const field = this.activeDestination.block === businessTrip.DESTINATION.transitStart
          ? 'startPoint'
          : 'endPoint';

        this.updateTransit({
          index: this.activeDestination.index,
          field,
          value: val,
        });
      } else if (
        this.activeDestination.block === businessTrip.DESTINATION.expenses
      ) {
        this.updateExpensesCity({
          index: this.activeDestination.index,
          city: val,
        });
        this.$emit('input');
      }

      Hub.$emit('toggle-modal', { modalName: 'destinationModal', newValue: false });
    },
    setDestination() {
      if (this.editableDestination) {
        const editableDestination = JSON.parse(
          JSON.stringify(this.editableDestination),
        );
        /* eslint-disable prefer-object-spread */
        this.region = Object.assign({}, editableDestination.region);
        this.area = Object.assign({}, editableDestination.area);
        this.city = Object.assign({}, editableDestination.city);
        this.settlement = Object.assign({}, editableDestination.settlement);
        /* eslint-enable prefer-object-spread */
      }
    },
    clear(destination) {
      // eslint-disable-next-line default-case
      switch (destination) {
      case 'region':
        this.area = null;
        this.city = null;
        this.settlement = null;
        break;
      case 'area':
        this.city = null;
        this.settlement = null;
        break;
      case 'city':
        this.settlement = null;
        break;
      }
    },
  },
  mounted() {
    this.setDestination();
  },
};
</script>

<style lang="scss" scoped>
$base-margin: 20px;
.destination-choice__title {
  text-align: left;
}
.destination-choice__item {
  position: relative;
  display: block;
  padding-bottom: $base-margin;
  &:first-child {
    z-index: 4;
  }
  &:nth-child(2) {
    z-index: 3;
  }
  &:nth-child(3) {
    z-index: 2;
  }
  &:nth-child(4) {
    z-index: 1;
  }
}
.destination-choice__actions {
  display: flex;
  justify-content: space-between;
}
</style>
