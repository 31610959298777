<template>
  <div class="business-trip__employee-cnt">
    <template v-if="value">
      <div class="business-trip__employee">
        <div
          class="business-trip__employee-photo"
          role="button"
          :title="value.fullName"
          @click="toggleMoreInfo"
        >
          <esmp-user-avatar
            :username="value.fullName || ''"
            :image-url="value.photo"
          />
        </div>
        <div class="business-trip__employee-info">
          <div
            class="business-trip__employee-name"
            role="button"
            @click="toggleMoreInfo"
          >
            {{ value.fullName }}
            <div class="business-trip__employee-info-icon">
              <esmp-icon
                name="info"
              />
            </div>
          </div>
          <div
            class="business-trip__employee-position"
            v-html="value.jobFullName"
          />
          <ul class="business-trip__employee-full-info" v-if="isMoreInfoShow">
            <li class="business-trip__efi__item">
              <span>Блок</span> {{ value.block }}
            </li>
            <li class="business-trip__efi__item">
              <span>Департамент</span> {{ value.department }}
            </li>
            <li class="business-trip__efi__item">
              <span>Отдел</span> {{ value.organization }}
            </li>
            <li class="business-trip__efi__item">
              <span>E-mail</span> {{ value.email }}
            </li>
            <li class="business-trip__efi__item">
              <span>Бюджетная единица</span>
              {{ value.budgetUnitName }}
            </li>
          </ul>
        </div>
      </div>

      <div class="business-trip__employee-btn">
        <esmp-button
          @click="changeCurrentUser(null)"
        >
          Изменить
        </esmp-button>
      </div>
    </template>
    <template v-else="">
      <div class="business-trip__employee-search">
        <div class="business-trip__employee-input">
          <user-search
            placeholder="Поиск сотрудника"
            :error-message="errorMessage"
            :value="value"
            @input="changeCurrentUser"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import UserSearch from './user-search.vue';

export default {
  name: 'Employee',
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  components: {
    UserSearch,
  },
  data() {
    return {
      isMoreInfoShow: false,
    };
  },
  methods: {
    toggleMoreInfo() {
      this.isMoreInfoShow = !this.isMoreInfoShow;
    },
    changeCurrentUser(user = null) {
      this.$emit('input', user);
    },
  },
};
</script>

<style scoped lang="scss">
$gray-dark: #666;
.business-trip__employee {
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
}
.business-trip__created-app .created-app__footer {
  min-height: 65px;
}
.business-trip__employee-photo {
  margin-right: 20px;
  background: no-repeat center / cover;

  .esmp-user-avatar {
    width: 50px;
    height: 50px;
  }
}
.business-trip__employee-info {
  width: calc(100% - 70px);
  margin-top: 6px;
}
.business-trip__employee-photo:hover {
  cursor: pointer;
}
.business-trip__employee-name {
  position: relative;
  display: inline-block;
  width: auto;
  min-height: 24px;
  font-size: 20px;
  line-height: 24px;
  color: #101828;
  letter-spacing: 0;
  transition: color 0.2s;
}

.business-trip__employee-info-icon {
  position: absolute;
  top: 0;
  left: calc(100% + 10px);
}

.business-trip__employee-name:hover,
.business-trip__employee-photo:hover
  + .business-trip__employee-info
  .business-trip__employee-name {
  color: $color-primary-1-day;
  cursor: pointer;
}
.business-trip__employee-position {
  margin-top: 6px;
  margin-bottom: 4px;
  font-size: 12px;
  color: $gray-dark;
  letter-spacing: 0;
}
.business-trip__employee-cnt {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.business-trip__employee-full-info {
}
.business-trip__efi__item {
  display: block;
  font-size: 12px;
  color: $gray-dark;
  letter-spacing: 0;
  list-style-type: none;
  & + & {
    margin-top: 4px;
  }
}
.business-trip__efi__item span {
  color: #101828;
}
.business-trip__efi__item span::after {
  content: ': ';
}

.business-trip__simple-btn {
  padding: 15px 36px;
  font-size: 20px;
  color: #101828;
  text-align: center;
  letter-spacing: 0;
  transition: background-color 0.2s;
}
.business-trip__simple-btn:hover {
  background-color: white;
}

.business-trip__simple-btn .button button {
  padding-right: 36px;
  padding-left: 36px;
  background: transparent;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: white;
  }
}
.business-trip__employee-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
}
.business-trip__employee-input {
  flex-grow: 1;
  padding-right: 20px;
}
</style>
