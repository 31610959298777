export default (obj1, obj2) => {
  const props1 = Object.getOwnPropertyNames(obj1);
  const props2 = Object.getOwnPropertyNames(obj2);

  if (props1.length !== props2.length) {
    return false;
  }

  for (let i = 0; i < props1.length; i += 1) {
    const propName = props1[i];

    if (obj1[propName] !== obj2[propName]) {
      return false;
    }
  }
  return true;
};
